import React, { useEffect, useRef, useState } from 'react';
import NavbarAndSideBar from '../../basic/navbarAndSideBar';
import { Row, Col } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import { useAddEventMutation } from '../../../features/property/propertySlice';
import { GoogleMap, LoadScript,  MarkerF,useJsApiLoader ,Autocomplete } from '@react-google-maps/api';
import map from "../../../assets/images/map/map.jpg"
import marker from "../../../assets/images/map/marker.png"
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useGetEventCategoryMutation } from '../../../features/property/propertySlice';

import { useNavigate } from 'react-router-dom';
import {
	setKey,	
	setLanguage,
	fromLatLng,
  } from "react-geocode"
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);
const placesLibrary = ['places']

function AddNewEvent() {
    const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg",
		libraries: placesLibrary,
		language:"en",
		region:"GH",
	  })

	
	
  // Set the API key for react-geocode
  setKey("AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg");

  // Set response language. Defaults to english.
  setLanguage("en");
    const [addEvent, { isLoading }] = useAddEventMutation();
    const [addEvent1, { isLoading:isLoading1 }] = useAddEventMutation();
    const [getEventCategory, { isLoading: isLoadingCategories }] = useGetEventCategoryMutation();
    const [eventCategory,setEventCategoryData]=useState()
    const [selectedoptions,setSelectedOptions]=useState([])

    const formRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        location: "",
        event_type: "",
        slug: "",
        price: "",
        latitude:"",
        longitude:"",
        category: [],
        other_image:[]
    });
    const [files, setFiles] = useState([]);
    const [other_images, setOtherImages] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, multiple, selectedOptions } = e.target;
    
        if (multiple) {
            // Handle multiple select input
            const values = Array.from(selectedOptions, (option) => option.value);
            setFormData({
                ...formData,
                [name]: values,
            });
            setSelectedOptions(values); // Update the state for selected options if needed
        } else {
            // Handle other input types (text, select, etc.)
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    
    

    const handlegetEventCategory =async (e) => {
        try{

            const response = await getEventCategory().unwrap();
            setEventCategoryData(response.results);

        }
        catch (error) {
            console.error('Error fetching event categories:', error);
        }
    }

    useEffect(()=>{
        handlegetEventCategory();
    },[])

    const handleAddEvent = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();
        if (isValid) {
            try {
                console.log(formData);
                console.log(other_images);
                console.log(formData.category);
    
                const formDataObj = new FormData();
    
                // Append form data fields one by one
                formDataObj.append('title', formData.title);
                formDataObj.append('description', formData.description);
                formDataObj.append('start_date', formData.start_date);
                formDataObj.append('end_date', formData.end_date);
                formDataObj.append('location', formData.location);
                formDataObj.append('event_type', formData.event_type);
                formDataObj.append('slug', formData.slug);
                formDataObj.append('price', formData.price);
                formDataObj.append('latitude', formData.latitude);
                formDataObj.append('longitude', formData.longitude);

                if (formData.category.length > 0) {
                    formData.category.forEach((categoryId) => {
                        formDataObj.append('categories', categoryId);
                        console.log(categoryId);
                    });
                }
    
    
                // Append images (primary image)
                if (files.length > 0) {
                    files.forEach((file) => {
                        formDataObj.append('featured_image', file.file);
                    });
                }
    
                // Append other images (if it’s an array)
                if (other_images.length > 0) {
                    other_images.forEach((imageFile, index) => {
                        formDataObj.append('other_images', imageFile.file);
                        console.log(imageFile.file);
                    });
                }
    
                // Log the FormData contents
                for (let pair of formDataObj.entries()) {
                    console.log(`${pair[0]}: ${pair[1]}`);
                }
    
                const response = await addEvent(formDataObj).unwrap();
                console.log(response);
                setShowSuccess(true);
                setFormData({
                    title: "",
                    description: "",
                    start_date: "",
                    end_date: "",
                    location: "",
                    event_type: "",
                    slug: "",
                    price: "",
                    latitude: "",
                    longitude: "",
                    category: [],
                });
                setFiles([]);
                setOtherImages([]);
                navigate("/events");
            } catch (e) {
                console.log(e);
                setShowError(true);
            }
        } else {
            formRef.current.classList.add("was-validated");
        }
    };
    

    const handleAddEventAndCreateAnother = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();
        if (isValid) {
            try {
                console.log(formData);
                console.log(other_images);
                console.log(formData.category);
    
                const formDataObj = new FormData();
    
                // Append form data fields one by one
                formDataObj.append('title', formData.title);
                formDataObj.append('description', formData.description);
                formDataObj.append('start_date', formData.start_date);
                formDataObj.append('end_date', formData.end_date);
                formDataObj.append('location', formData.location);
                formDataObj.append('event_type', formData.event_type);
                formDataObj.append('slug', formData.slug);
                formDataObj.append('price', formData.price);
                formDataObj.append('latitude', formData.latitude);
                formDataObj.append('longitude', formData.longitude);

                if (formData.category.length > 0) {
                    formData.category.forEach((categoryId) => {
                        formDataObj.append('categories', categoryId);
                        console.log(categoryId);
                    });
                }
    
    
                // Append images (primary image)
                if (files.length > 0) {
                    files.forEach((file) => {
                        formDataObj.append('featured_image', file.file);
                    });
                }
    
                // Append other images (if it’s an array)
                if (other_images.length > 0) {
                    other_images.forEach((imageFile, index) => {
                        formDataObj.append('other_images', imageFile.file);
                        console.log(imageFile.file);
                    });
                }
    
                // Log the FormData contents
                for (let pair of formDataObj.entries()) {
                    console.log(`${pair[0]}: ${pair[1]}`);
                }
    
                const response = await addEvent(formDataObj).unwrap();
                console.log(response);
                setShowSuccess(true);
                setFormData({
                    title: "",
                    description: "",
                    start_date: "",
                    end_date: "",
                    location: "",
                    event_type: "",
                    slug: "",
                    price: "",
                    latitude: "",
                    longitude: "",
                    category: [],
                });
                setFiles([]);
                setOtherImages([]);
            } catch (e) {
                console.log(e);
                setShowError(true);
            }
            
        } else {
            formRef.current.classList.add("was-validated");
        }
    };

    // Get today's date in the format YYYY-MM-DDTHH:MM
    const getTodaysDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const hh = String(today.getHours()).padStart(2, '0');
        const mi = String(today.getMinutes()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}T${hh}:${mi}`;
    };

    // Handle setting the minimum end date based on the selected start date
    const handleStartDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            end_date: formData.end_date < value ? value : formData.end_date
        });
    };
    

    const containerStyle = {
		width: '100%',
		height: '380px'
	  };

      const center = {
		lat: 5.5593,
		lng: 0.1974,
	  };


    const latref=useRef()
    const longref=useRef()
    const [markerPosition, setMarkerPosition] = useState(null);


    const getAddress = () => {
        fromLatLng(latref.current.value, longref.current.value).then(
          response => {
            const address = response.results[0].formatted_address;
            const city = response.results[0].address_components.find(component => component.types.includes("locality"))?.long_name;
            const region = response.results[0].address_components.find(component => component.types.includes("administrative_area_level_1"))?.long_name;
            console.log(address)
            console.log(city)
            console.log(region)
            
           
            
          },
          error => {
            console.error(error);
          }
        );
      };
    
    const 	handleClickMap=(e)=>{	
		const latlng=e.latLng.toJSON()
		

        setFormData((prevData) => ({
            ...prevData,
            latitude: latlng.lat,
            longitude: latlng.lng,
          }));
		setMarkerPosition(e.latLng.toJSON());

		getAddress()
	} 
    const [autocomplete, setAutocomplete] = useState(null);

    const locationRef=useRef()
    

    const handlePlaceSelected = () => {
		if (locationRef.current) {
			const place = locationRef.current.getPlace();
			console.log('Selected Place:', place);
	
			const latitude = place.geometry.location.lat();
			const longitude = place.geometry.location.lng();
	
			
			latref.current.value = latitude;
			longref.current.value = longitude;
			
	
			setMarkerPosition({
				lat: latitude,
				lng: longitude
			});
	
			let region = '';
		;
	
			

	
			console.log('Region:', region);
		}
	};

    
      const onLoad = (autoC) => setAutocomplete(autoC);

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className=''>
                                <div className="">
                                    <h3 className="card-title">Create Event</h3>
                                </div>
                                <div className='p-2'>
                                    {showSuccess &&
                                        <div className="alert alert-success" role="alert">
                                            <div className="iq-alert-text">Event created successfully</div>
                                            <button onClick={() => { setShowSuccess(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <i className="ri-close-line" style={{ color: "black" }}></i>
                                            </button>
                                        </div>
                                    }


                                    {showError &&
                                        <div className="alert alert-danger" role="alert">
                                            <div className="iq-alert-text">Enter Valid data,Try again</div>
                                            <button onClick={() => { setShowError(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <i className="ri-close-line" style={{ color: "black" }}></i>
                                            </button>
                                        </div>
                                    }
                                    <form ref={formRef} className='w-100 needs-validation' noValidate>
                                        <div className="row border p-2 rounded-sm py-4">
                                            <div className="col">
                                                <label>Title <span className='text-danger'>*</span></label>
                                                <input type="text" name='title' className="form-control" required placeholder="" value={formData.title} onChange={handleChange} />
                                            </div>
                                            <div className="col">
                                                <label>Slug <span className='text-danger'>*</span></label>
                                                <input type="text" name='slug' className="form-control" required placeholder="" value={formData.slug} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row border p-2 rounded-sm mt-5">
                                            
                                            <div className="col-md-6">
                                                <label>Start Date <span className='text-danger'>*</span></label>
                                                <input type="datetime-local" className="form-control" name='start_date' required value={formData.start_date} min={getTodaysDate()} onChange={handleStartDateChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>End Date <span className='text-danger'>*</span></label>
                                                <input type="datetime-local" className="form-control" name='end_date' required value={formData.end_date} min={formData.start_date || getTodaysDate()} onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Image <span className='text-danger'>*</span></label>
                                                <FilePond
                                                    files={files}
                                                    allowMultiple={false}
                                                    name="avatar_url"
                                                    stylePanelLayout="compact"
                                                    styleButtonRemoveItemPosition="left"
                                                    styleButtonProcessItemPosition="right"
                                                    styleLoadIndicatorPosition="right"
                                                    styleProgressIndicatorPosition="right"
                                                    styleButtonRemoveItemAlign={false}
                                                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                                    onupdatefiles={setFiles}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Other Images <span className='text-danger'>*</span></label>
                                                <FilePond
                                                    files={other_images}
                                                    allowMultiple={true}
                                                    name="avatar_url"
                                                    stylePanelLayout="compact"
                                                    styleButtonRemoveItemPosition="left"
                                                    styleButtonProcessItemPosition="right"
                                                    styleLoadIndicatorPosition="right"
                                                    styleProgressIndicatorPosition="right"
                                                    styleButtonRemoveItemAlign={false}
                                                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                                    onupdatefiles={setOtherImages}
                                                />
                                            </div>
                                        </div>
                     
                                        <div className="row border p-2 rounded-sm mt-5">
                                            <div className="col-12">
                                                <label>Location <span className='text-danger'>*</span></label>
                                
                                                <input type="text"  ref={locationRef} name='location' className="form-control" required placeholder="" value={formData.location} onChange={handleChange} />
                                            </div>
                                            <div className='col-12 mt-4'>
                                            <label>Select location on map <span className='text-danger'>*</span></label>
                                            {!isLoaded ? 
	
    <img className="rounded-3" src={map} alt="Map"/> 
     
 : <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition || center}
      zoom={10}
      
      onClick={handleClickMap}

    >
    
      {markerPosition && <MarkerF position={markerPosition} icon={marker}   />}
    </GoogleMap>

 

}	


                                            </div>
                                            <div className="col-md-6">
                                                <label>Latitude <span className='text-danger'>*</span></label>
                                                <input type="text" ref={latref} name='latitude' className="form-control" required placeholder="" value={formData.latitude} onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Longitude <span className='text-danger'>*</span></label>
                                                <input type="text" ref={longref} name='longitude' className="form-control" required placeholder="" value={formData.longitude} onChange={handleChange} />
                                            </div>
                                           
                                        </div>

                                        <div className="row border p-2 rounded-sm mt-5">
                                        <div className="col-md-6">
                                                <label>Price <span className='text-danger'>*</span></label>
                                                <input type="number" name='price' className="form-control" required placeholder="" value={formData.price} onChange={handleChange} />
                                            </div>
                                          
                                            <div className="col-md-6 ">
                                                <label>Status <span className='text-danger'>*</span></label>
                                                <select className="form-control" required name="event_type" onChange={handleChange} value={formData.event_type}>
                                                    <option value="" disabled>Select an option</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </div>


                                            <div className="col-md-6 mt-2">
                                                <label>Category <span className='text-danger'>*</span></label>
                                                <select  multiple className="form-control" required name="category" onChange={handleChange} value={formData.category}>
                                                    {eventCategory&&eventCategory.map((category,index)=>{
                                                        return(
                                                            <option key={index} value={category.id}>{category.title}</option>

                                                        )
                                                    })}
                                                </select>
                                            </div>

</div>

                                        <div className="row border p-2 rounded-sm mt-5">
                                            <div className="col-12">
                                                <label>Description <span className='text-danger'>*</span></label>
                                                <textarea className="form-control" required name="description" onChange={handleChange} value={formData.description}></textarea>
                                            </div>
                                        </div>
                                        <div className='mt-4'>
                                            <button className="btn btn-primary" onClick={handleAddEvent}>
                                            
                                            {isLoading ?<> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Creating... </> : 'Create Event'}</button>
                                            <button className="btn btn-secondary mx-3" onClick={handleAddEventAndCreateAnother}>{isLoading1 ? <> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Creating... </>  : 'Create Event and Create Another'}</button>

                                            <button className="btn iq-bg-danger " type='button' onClick={() => navigate(-1)}>Cancel</button>  
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AddNewEvent;
