import React, { useEffect, useRef, useState } from 'react';
import NavbarAndSideBar from '../../basic/navbarAndSideBar';
import { Row, Col } from 'reactstrap';
import { useGetEventsDetailMutation,useEditEventMutation,useGetEventCategoryMutation } from '../../../features/property/propertySlice';
import { FilePond, registerPlugin } from 'react-filepond';
import { useNavigate ,useParams} from 'react-router-dom';
// Import FilePond styles and plugins
import { GoogleMap, LoadScript,  MarkerF,useJsApiLoader ,Autocomplete } from '@react-google-maps/api';
import map from "../../../assets/images/map/map.jpg"
import marker from "../../../assets/images/map/marker.png"
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {
	setKey,	
	setLanguage,
	fromLatLng,
  } from "react-geocode"
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);
const placesLibrary = ['places']


const apiUrl = process.env.REACT_APP_API_URL


function EditEventPage() {

  const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg",
		libraries: placesLibrary,
		language:"en",
		region:"GH",
	  })

	
	
  // Set the API key for react-geocode
  setKey("AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg");

  // Set response language. Defaults to english.
  setLanguage("en");


  const [getEventDetail, { isLoading }] = useGetEventsDetailMutation();
  const [getEventCategories, { isLoading:isLoadingCategories }] = useGetEventCategoryMutation();

  const [editEvent, {isLoading:isEditing }] = useEditEventMutation();
  const formRef=useRef(null)
  const navigate=useNavigate();
  const {id}=useParams()
  console.log(id)
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    location: "",
    event_type: "",
    slug: "",
    price: "",
    latitude:"",
    longitude:"",
    category: [],
    other_image:[]
});
  const [title,setTitle]=useState()
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [files, setFiles] = useState([]);
  const [other_images,setOtherImages] = useState([]);
  const [categories,setCategories]=useState([]);

  useEffect(() => {
    handleGetEvent();
  }, []);

  const handleGetEvent = async () => {
    try {
      const response = await getEventDetail(localStorage.getItem("event")).unwrap();
      setTitle(response.title);
      setSelectedOptions(response.category_detail.map(category => category.id));
      const categories=await getEventCategories().unwrap();
      console.log(categories,"categories");
      setCategories(categories.results);

      setFormData(response);
      setMarkerPosition({
        lat:response.latitude,lng:response.longitude
      }
      )
      fetch(response.featured_image)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'image.jpg', { type: blob.type });
          setFiles([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });


        const otherFilesPromises = response.other_image.map(async (file, index) => {
          const response = await fetch(file.image);
          const blob = await response.blob();
          return new File([blob], `other_image${index}.jpg`, { type: blob.type });
        });
  
        const otherFiles = await Promise.all(otherFilesPromises);
        const filePondFiles = otherFiles.map(file => ({
          source: file,
          options: {
            type: 'local'
          }
        }));
        setOtherImages(filePondFiles);

        


      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, multiple, selectedOptions } = e.target;

    if (multiple) {
        // Handle multiple select input
        const values = Array.from(selectedOptions, (option) => option.value);
        setFormData({
            ...formData,
            [name]: values,
        });
        setSelectedOptions(values); // Update the state for selected options if needed
    } else {
        // Handle other input types (text, select, etc.)
        setFormData({
            ...formData,
            [name]: value,
        });
    }
};

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };


  const containerStyle = {
		width: '100%',
		height: '380px'
	  };

      const center = {
		lat: 5.5593,
		lng: 0.1974,
	  };
  const handleEditEvent = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try {
        


        const formDataObj = new FormData();
    
        // Append form data fields one by one
        formDataObj.append('title', formData.title);
        formDataObj.append('description', formData.description);
        formDataObj.append('start_date', formData.start_date);
        formDataObj.append('end_date', formData.end_date);
        formDataObj.append('location', formData.location);
        formDataObj.append('event_type', formData.event_type);
        formDataObj.append('slug', formData.slug);
        formDataObj.append('price', formData.price);
        formDataObj.append('latitude', formData.latitude);
        formDataObj.append('longitude', formData.longitude);

        if (formData.category.length > 0) {
            formData.category.forEach((categoryId) => {
                formDataObj.append('categories', categoryId);
                console.log(categoryId);
            });
        }


        // Append images (primary image)
        if (files.length > 0) {
            files.forEach((file) => {
                formDataObj.append('featured_image', file.file);
            });
        }
      
        // Append other images (if it’s an array)
        if (other_images.length > 0) {
            other_images.forEach((imageFile, index) => {
                formDataObj.append('other_images', imageFile);
                console.log(imageFile);
            });
          }
        // Log formData contents
        for (const pair of formDataObj.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }
  

       

        const response = await editEvent({ id: id, formData: formDataObj }).unwrap();
        console.log(response);
        setFormData({
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            location: "",
            event_type: "",
            slug: "",
            price: "",
            latitude: "",
            longitude: "",
            category: [],
        });
        setFiles([]);
        setOtherImages([]);
        navigate("/events")
      } catch (e) {
        console.log(e);
      }
    } else {
      formRef.current.classList.add("was-validated");
    }
  };
  const handleUpdateFiles = (fileItems) => {
    setOtherImages(fileItems.map(fileItem => fileItem.file));
  };

  const latref=useRef()
  const longref=useRef()
  const [markerPosition, setMarkerPosition] = useState();


  const getAddress = () => {
      fromLatLng(latref.current.value, longref.current.value).then(
        response => {
          const address = response.results[0].formatted_address;
          const city = response.results[0].address_components.find(component => component.types.includes("locality"))?.long_name;
          const region = response.results[0].address_components.find(component => component.types.includes("administrative_area_level_1"))?.long_name;
          console.log(address)
          console.log(city)
          console.log(region)
          
         
          
        },
        error => {
          console.error(error);
        }
      );
    };
  
  const 	handleClickMap=(e)=>{	
  const latlng=e.latLng.toJSON()
  

      setFormData((prevData) => ({
          ...prevData,
          latitude: latlng.lat,
          longitude: latlng.lng,
        }));
  setMarkerPosition(e.latLng.toJSON());

  getAddress()
} 
  const [autocomplete, setAutocomplete] = useState(null);

  const locationRef=useRef()

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12">
              <div className=''>
                <div className="">
                  <h3 className="card-title">Edit {title}</h3>
                </div>
                <div className='p-2'>
                  <form ref={formRef} onSubmit={handleEditEvent} className='w-100 needs-validation' noValidate>
                    <div className="row border p-2 rounded-sm py-4">
                      <div className="col">
                        <label>Title <span className='text-danger'>*</span></label>
                        <input type="text" name='title'  className="form-control" required placeholder="" value={formData && formData.title} onChange={handleChange} />
                      </div>
                      <div className="col">
                        <label>Slug <span className='text-danger'>*</span></label>
                        <input type="text" name='slug'  className="form-control" required placeholder="" value={formData && formData.slug} onChange={handleChange} />
                      </div>
                    </div>

                    <div className="row border p-2 rounded-sm mt-5">
                      <div className="col-12">
                        <label>Image <span className='text-danger'>*</span></label>
                        <FilePond
                          files={files}
                          allowMultiple={false}
                   
                          name="avatar_url"
                          stylePanelLayout="compact"
                          styleButtonRemoveItemPosition="left"
                          styleButtonProcessItemPosition="right"
                          styleLoadIndicatorPosition="right"
                          styleProgressIndicatorPosition="right"
                          styleButtonRemoveItemAlign={false}
                          acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                          onupdatefiles={setFiles}
                          
                        />
                      </div>

                      <div className="col-md-6">
                        <label>Start Date<span className='text-danger'>*</span></label>
                        <input type="datetime-local" className="form-control" id="exampleInputdatetime" name='start_date' value={formData && formatDateTime(formData.start_date)} onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <label>End Date <span className='text-danger'>*</span></label>
                        <input type="datetime-local" className="form-control" id="exampleInputdatetime" name='end_date' value={formData && formatDateTime(formData.end_date)} onChange={handleChange} />
                      </div>
                    </div>

                    <div className="row border p-2 rounded-sm mt-5">
                      <div className="col-12">
                        <label>Location<span className='text-danger'>*</span></label>
                        <input type="text" name='location' className="form-control" required placeholder="" value={formData && formData.location} onChange={handleChange} />
                      </div>

                      <div className='col-12 mt-4'>
                                            <label>Select location on map <span className='text-danger'>*</span></label>
                                            {!isLoaded ? 
	
    <img className="rounded-3" src={map} alt="Map"/> 
     
 : <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition || center}
      zoom={10}
      
      onClick={handleClickMap}

    >
    
      {markerPosition && <MarkerF position={markerPosition} icon={marker}   />}
    </GoogleMap>

 

}	


                                            </div>
                                            <div className="col-md-6">
                                                <label>Latitude <span className='text-danger'>*</span></label>
                                                <input type="text" ref={latref} name='latitude' className="form-control" required placeholder="" value={formData.latitude} onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Longitude <span className='text-danger'>*</span></label>
                                                <input type="text" ref={longref} name='longitude' className="form-control" required placeholder="" value={formData.longitude} onChange={handleChange} />
                                            </div>
                      <div className="col-md-6">
                        <label>Price <span className='text-danger'>*</span></label>
                        <input type="number" name='price' className="form-control" required placeholder="" value={formData && formData.price} onChange={handleChange} />
                      </div>


                      <div className="col-md-6 mt-2">
                                                <label>Status <span className='text-danger'>*</span></label>
                                                <select className="form-control" required name="event_type" onChange={handleChange} value={formData&&formData.event_type}>
                                                    <option value="" disabled>Select an option</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 mt-2">
                          <label>Categories <span className='text-danger'>*</span></label>
                          <select
                            multiple
                            type="text"
                            name='category'
                            className="form-control"
                            required
                            placeholder=""
                            value={selectedOptions}
                            onChange={handleChange}
                          >
                            {categories && categories.map((category, index) => (
                              <option key={index} value={category.id}>{category.title}</option>
                            ))}
                          </select>
                        </div>
                    </div>

                    <div className="row border p-2 rounded-sm mt-5">
                      <div className="col-12">
                        <label>Description</label>
                        <textarea className="form-control" name="description" rows="3" value={formData && formData.description} onChange={handleChange}></textarea>
                      </div>
                    </div>

                      
                      
                          <div  className='col-md-6 border p-2 rounded-sm '>
                          <div className="col-md-12">
                          <label>Other Files</label>
                          <FilePond
                            files={other_images}
                            allowMultiple={true}
                            onupdatefiles={handleUpdateFiles}
                          />
                        </div>
                          </div>

                          
                  

                    <div className='mt-4'>
                                            <button className="btn btn-primary ">
                                            
                                            {isEditing ?<> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> saving ... </> : 'Save'}</button>

                                            <button className="btn iq-bg-danger ml-3 " type='button' onClick={() => navigate(-1)}>Cancel</button>  
                                        </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditEventPage;
