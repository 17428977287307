import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetCategoriesMutation } from '../../features/property/propertySlice';
import AddPropertyCategoryModal from '../../components/property/category/addPropertyCategoryModal';
import ViewPropertyCategoryModal from '../../components/property/category/viewPropertyCategoryModal';
import EditPropertyCategoryModal from '../../components/property/category/editPopertyCategoryModal';
import DeletePropertyCategoryModal from '../../components/property/category/deletePropertyCategoryModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomPagination from '../../components/basic/customPagination';

function PropertyCategory() {
    const [getCategories] = useGetCategoriesMutation();
    const [categories, setCategories] = useState([]);
    const [categoryDetail, setCategoryDetail] = useState(null);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const handleGetCategories = async (page, perPage) => {
        setPending(true);
        try {
            const response = await getCategories({ page, limit: perPage }).unwrap();
            setCategories(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetCategories(page, perPage);
    }, [page, perPage]);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    const filteredItems = categories.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [propertyCategoryModal, setPropertyCategoryModal] = useState(false);
    const toggleModal = () => {
        setPropertyCategoryModal(!propertyCategoryModal);
        handleGetCategories(page, perPage);
    };

    const [viewPropertyCategoryModal, setViewPropertyCategoryModal] = useState(false);
    const [editPropertyCategoryModal, setEditPropertyCategoryModal] = useState(false);
    const [deletePropertyCategoryModal, setDeletePropertyCategoryModal] = useState(false);

    const toggleViewModal = () => {
        setViewPropertyCategoryModal(!viewPropertyCategoryModal);
    };

    const toggleEditModal = () => {
        setEditPropertyCategoryModal(!editPropertyCategoryModal);
        handleGetCategories(page, perPage);
    };

    const toggleDeleteModal = () => {
        setDeletePropertyCategoryModal(!deletePropertyCategoryModal);
        handleGetCategories(page, perPage);
    };

    const handleGetCategoryDetail = (row) => {
        setCategoryDetail(row);
        setViewPropertyCategoryModal(true);
    };

    const handleEditCategory = (row) => {
        setCategoryDetail(row);
        setEditPropertyCategoryModal(true);
    };

    const handleDeleteCategory = (row) => {
        setCategoryDetail(row);
        setDeletePropertyCategoryModal(true);
    };

    const columns = [
        {
            id: 'id',
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Slug',
            selector: row => row.slug,
        },
        {
            name: 'Icon',
            selector: row => row.icon,
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Property Categories</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New property category</button>
                                <AddPropertyCategoryModal propertyCategoryModal={propertyCategoryModal} toggleModal={toggleModal} />
                                <ViewPropertyCategoryModal viewPropertyCategoryModal={viewPropertyCategoryModal} toggleViewModal={toggleViewModal} categoryDetail={categoryDetail} />
                                <EditPropertyCategoryModal editPropertyCategoryModal={editPropertyCategoryModal} toggleEditModal={toggleEditModal} categoryDetail={categoryDetail} />
                                <DeletePropertyCategoryModal deletePropertyCategoryModal={deletePropertyCategoryModal} toggleDeleteModal={toggleDeleteModal} categoryDetail={categoryDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                    
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%", padding: "10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                            defaultSortFieldId="id"
                                            defaultSortAsc={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PropertyCategory;
