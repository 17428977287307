import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetAllPostCategoriesMutation } from '../../features/property/propertySlice';
import AddBlogModal from '../../components/blog/addBlogCategoryModal';
import EditBlogCategoryModal from '../../components/blog/editBlogCategoryModal';
import DeleteBlogCategoryModal from '../../components/blog/deleteBlogCategoryModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PostCategories() {
    const [getCategories, { isLoading }] = useGetAllPostCategoriesMutation();
    const [categories, setCategories] = useState([]);
    const [categoryDetail, setCategoryDetail] = useState(null);
    const [pending, setPending] = useState(true);

    const handleGetCategories = async () => {
        try {
            const response = await getCategories().unwrap();
            setCategories(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetCategories();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = categories.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [addBlogModal, setAddBlogModal] = useState(false);
    const toggleModal = () => {
        setAddBlogModal(!addBlogModal);
        handleGetCategories();
    }

    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [deleteBlogCategoryModal, setDeleteBlogCategoryModal] = useState(false);

    const toggleEditModal = (row) => {
        setEditCategoryModal(!editCategoryModal);
    
        handleGetCategories();
    }

    const toggleDeleteModal = () => {
        setDeleteBlogCategoryModal(!deleteBlogCategoryModal);
        handleGetCategories();
    }


    const handleEditCategory = (row) => {
        setCategoryDetail(row);
        setEditCategoryModal(true);
    }

    const handleDeleteCategory = (row) => {
        setCategoryDetail(row);
        setDeleteBlogCategoryModal(true);
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
      
        return `${String(day).padStart(2, '0')} ${month}, ${year}`;
      };

    const columns = [
        {
            id: 'id',  
            name: 'Parent Category',
            selector: row =>row.parent_category.name? row.parent_category.name : "No parent category" ,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Date Created',
            selector: row =>formatDate(row.created_at),
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
   
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Post Categories</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New post category</button>
                                <AddBlogModal addBlogModal={addBlogModal} toggleModal={toggleModal} />
                                <EditBlogCategoryModal editCategoryModal={editCategoryModal} toggleEditModal={toggleEditModal} categoryDetail={categoryDetail} />
                                <DeleteBlogCategoryModal deleteBlogCategoryModal={deleteBlogCategoryModal} toggleDeleteModal={toggleDeleteModal} categoryDetail={categoryDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%",padding:"10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                            
                                            defaultSortFieldId="id" // Sorting by 'Name' column
                                            defaultSortAsc={false}

                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PostCategories;
