import './App.css';
import { Route,Routes ,BrowserRouter as Router} from 'react-router-dom';

import "./assets/css/bootstrap.min.css"
import "./assets/css/typography.css"
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import LoginPage from './pages/authentication/loginPage';
import Roles from './pages/User_management/roles';
import Users from './pages/User_management/users';
import RequireAuth from './components/auth/requireAuth';
import PropertyCategory from './pages/property/propertyCategory';
import PropertyAmenities from './pages/property/propertyAmenities';
import PropertyPrevilege from './pages/property/productPrevilege';
import FAQS from './pages/enquiryandEvents/Faqs';
import Event from './pages/enquiryandEvents/events';
import Contact from './pages/enquiryandEvents/contacts';
import EditEventPage from './components/enquiryandfaqs/events/editEventPage';
import AddNewEvent from './components/enquiryandfaqs/events/addNewEvent';
import PropertyList from './pages/property/propertyList';
import EditSettingsPage from './pages/settings/editSettings';
import NewsLetterPage from './pages/settings/newLetterPage';
import PostCategories from './pages/blog/postCategories';
import PostTags from './pages/blog/postTags';
import Posts from './pages/blog/posts';
import AddNewBlog from './pages/blog/addBlogPage';
import EditPorpertyPage from './pages/property/editPropertyPage';
import AddNewPropertyPage from './pages/property/addNewPropertyPage';
import ViewPostDetailsPage from './pages/blog/viewPostDetailsPage';
import EditPostPage from './pages/blog/editPostPage';
import WebsiteLayout from './pages/settings/websiteLayout';
import UserDetailPage from './pages/User_management/userDetailPage';
import EditUserPage from './pages/User_management/editUserPage';
import FinancialReport from './pages/report/financialReport';
import RegistrationReport from './pages/report/registrationReport';
import PropertyReport from './pages/report/propertyReport';
import PartnersList from './pages/partners/partnersList';
import AddNewPartnerPage from './pages/partners/addNewPartnerPage';
import ViewPartnerDetailPage from './pages/partners/viewPartnerDetailPage';
import EditPartnerPage from './pages/partners/editPartnerPage';
import AddUserPage from './pages/User_management/addUserPage';
import Dashboard from './pages/dasboard/dashboard'
import EventsCategory from './pages/enquiryandEvents/eventscategory';
import PendingProperties from './pages/property/pendingProperties';
import PromoCodes from './pages/partners/promoCodes';
function App() {
  return (
    <div className="wrapper" >
    <Router>
    <Routes>
    <Route element={<RequireAuth />}>
    <Route path="/" element={<Dashboard/>} />
    <Route path='/users/roles' element={<Roles/>}/>

<Route path='/users/list' element={<Users/>}/>
<Route path='/users/add' element={<AddUserPage/>}/>

<Route path={`/users/:id`} element={<UserDetailPage/>}/>
<Route path={`/users/edit/:id`} element={<EditUserPage/>}/>
<Route path='/property-categories' element={<PropertyCategory/>}/>
<Route path='/property-amenities' element={<PropertyAmenities/>}/>
<Route path='/property-previleges' element={<PropertyPrevilege/>}/>
<Route path='/faqs' element={<FAQS/>}/>
<Route path='/events' element={<Event/>}/>
<Route path='/events-category' element={<EventsCategory/>}/>

<Route path='/events/edit/:id' element={<EditEventPage/>}/>
<Route path='/contacts' element={<Contact/>}/>
<Route path='/events/create' element={<AddNewEvent/>}/>
<Route path='/properties' element={<PropertyList/>}/>
<Route path='/properties/pending' element={<PendingProperties/>}/>

<Route path='/properties/edit/:id' element={<EditPorpertyPage/>}/>
<Route path='/properties/add' element={<AddNewPropertyPage/>}/>
<Route path='/settings' element={<EditSettingsPage/>}/>
<Route path='/news-letters' element={<NewsLetterPage/>}/>
<Route path='/post-categories' element={<PostCategories/>}/>
<Route path='/post-tags' element={<PostTags/>}/>

<Route path='/posts/:id' element={<ViewPostDetailsPage/>}/>
<Route path='/posts/edit/:id' element={<EditPostPage/>}/>
<Route path='/posts' element={<Posts/>}/>
<Route path='/website-layout' element={<WebsiteLayout/>}/>
<Route path='/admin/posts/create' element={<AddNewBlog/>}/>
<Route path='/report/financial' element={<FinancialReport/>}/>
<Route path='/report/registration' element={<RegistrationReport/>}/>
<Route path='/report/property' element={<PropertyReport/>}/>
<Route path='/partner/list' element={<PartnersList/>}/>
<Route path={`/partner/:id`} element={<ViewPartnerDetailPage/>}/>
<Route path={`/partner/edit/:id`} element={<EditPartnerPage/>}/>

<Route path='/partner/add' element={<AddNewPartnerPage/>}/>
<Route path='/partner/add/promo_code' element={<PromoCodes/>}/>







    </Route>
      
      <Route path='/login' element={<LoginPage/>}/>
     </Routes>
    </Router>
    
 
   
 
    </div>
  );
}

export default App;
