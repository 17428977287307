import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import AddPropertyCategoryModal from '../blog/addBlogCategoryModal';
import { useGetPropertiesMutation } from '../../features/property/propertySlice';
import ViewPropertyDetailModal from '../property/viewPropertyDetailModal';
import EditPropertyCategoryModal from '../property/category/editPopertyCategoryModal';
import DeletePropertyModal from '../property/deletePropertyModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ApprovePropertyModal from '../property/approvePropertyModal';

function PropertyList() {
    const [getProperties] = useGetPropertiesMutation();
    const [properties, setProperties] = useState([]);
    const [propertyDetail, setPropertyDetail] = useState(null);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const handleGetProperties = async (page, perPage) => {
        setPending(true);
        try {
            const response = await getProperties({ page, limit: perPage }).unwrap();
            setProperties(response.results);
            setTotalRows(response.count);
        } catch (e) {
            console.error(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetProperties(page, perPage);
    }, [page, perPage]);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    const filteredItems = properties.filter(
        item =>
            (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.address && item.address.toLowerCase().includes(filterText.toLowerCase()))
    );

    const [propertyCategoryModal, setPropertyCategoryModal] = useState(false);
    const toggleModal = () => {
        setPropertyCategoryModal(!propertyCategoryModal);
        handleGetProperties(page, perPage);
    };

    
    const [viewPropertyModal, setViewPropertyCategoryModal] = useState(false);
    const [editPropertyCategoryModal, setEditPropertyCategoryModal] = useState(false);
    const [deletePropertyModal, setDeletePropertyCategoryModal] = useState(false);
    const [approvePropertyModal, setApprovePropertyModal] = useState(false);

    const toggleViewModal = () => {
        setViewPropertyCategoryModal(!viewPropertyModal);
    };

    const toggleApproveModal = () => {
        setApprovePropertyModal(!approvePropertyModal);
        handleGetProperties();
    }

    const toggleEditModal = () => {
        setEditPropertyCategoryModal(!editPropertyCategoryModal);
        handleGetProperties(page, perPage);
    };

    const toggleDeleteModal = () => {
        setDeletePropertyCategoryModal(!deletePropertyModal);
        handleGetProperties(page, perPage);
    };

    const handleGetCategoryDetail = (row) => {
        setPropertyDetail(row);
        setViewPropertyCategoryModal(true);
    };

    const handleEditCategory = (row) => {
        setPropertyDetail(row);
        setEditPropertyCategoryModal(true);
    };

    const handleDeleteCategory = (row) => {
        setPropertyDetail(row);
        setDeletePropertyCategoryModal(true);
    };
    const handleApproveProperty = (row) => {
        setPropertyDetail(row);
        setApprovePropertyModal(true);
    }

    // Handle page change
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleRowsPerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setPage(1); // Reset to page 1 when changing rows per page
    };

    const columns = [
        {
            id: 'id',
            name: 'Image',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                    <img src={row.files} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
                </div>
            ),
            width: "fit-content",
        },
        {
            name: 'Title',
            selector: row => (
                <div>
                    <h5>{row.title}</h5>
                    {row.category.map((category, index) => (
                        <span key={index} className='text-muted fs-xs mr-1'>{category.name}</span>
                    ))}
                </div>
            ),
            width: '25%',
        },
        {
            name: 'Price',
            selector: row => (
                <div>
                    <span className="btn mb-1 iq-bg-primary">{row.pricing[0].currency} {row.pricing[0].price}</span>
                </div>
            ),
            width: '11%',
        },
        {
            name: 'Min Days',
            selector: row => (
                <div>
                    <span className="badge badge-pill badge-primary">{row.pricing[0].max_days_allowed}</span>
                </div>
            ),
            width: "fit-content",
        },
        {
            name: 'Address & Creation',
            selector: row => (
                <div>
                    <p>{row.address.length > 20 ? row.address.slice(0, 20) + "..." : row.address}</p>
                </div>
            ),
            width: '200px',
        },
        {
            name: 'Status',
            selector: row => (
                <div>
                    <p className='btn mb-1 iq-bg-primary'>{row.status}</p>
                </div>
            ),
            width: '120px',
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    {
                        row.status==="approved"?
                            ""
                        :
                        <div onClick={() => handleApproveProperty(row)} className='text-primary action-link mx-3' size="sm" >
                        <i className="ri-check-double-line mr-1"></i>
                        Approve
                    </div>
                    }
                 
                   
                    <Link className='text-primary action-link mx-3' size="sm" to={`/properties/edit/${row.id}`}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </Link>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width: "fit-content",
        },
    ];

    return (
        <>
            <Row style={{ marginBottom: "100px" }} className='px-lg-5 px-md-3 px-sm-2'>
                <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                    <div className='d-flex justify-content-between mb-4'>
                        <h4 className="card-title">Property List</h4>
                        <AddPropertyCategoryModal propertyCategoryModal={propertyCategoryModal} toggleModal={toggleModal} />
                        <ViewPropertyDetailModal viewPropertyModal={viewPropertyModal} toggleViewModal={toggleViewModal} propertyDetail={propertyDetail} />
                        <EditPropertyCategoryModal editPropertyCategoryModal={editPropertyCategoryModal} toggleEditModal={toggleEditModal} propertyDetail={propertyDetail} />
                        <DeletePropertyModal deletePropertyModal={deletePropertyModal} toggleDeleteModal={toggleDeleteModal} propertyDetail={propertyDetail} />
                        <ApprovePropertyModal approvePropertyModal={approvePropertyModal} toggleApproveModal={toggleApproveModal} propertyDetail={propertyDetail} />

                    </div>
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className='text-primary d-flex align-content-center hover-icon'>
                                <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                            </div>
                            <div className="search-box d-flex justify-content-end">
                                <input type="text" className="text search-input" placeholder="Search By Title or Address..."
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '1000px' }}>
                                <DataTable
                                    columns={columns}
                                    data={filteredItems}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    selectableRows
                                    onSelectedRowsChange={handleChange}
                                    clearSelectedRows={toggledClearRows}
                                   

                                    selectableRowsComponentProps={{
                                        type: 'checkbox',
                                    }}
                                    selectableRowsHighlight
                                    progressPending={pending}
                                    progressComponent={
                                        <div style={{ width: "100%" }}>
                                            <Skeleton style={{ width: "100%", height: "30px" }} />
                                            <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                            <Skeleton style={{ width: "100%", height: "30px" }} />
                                            <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default PropertyList;
