import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import { useNavigate } from 'react-router-dom';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useGetAmenitiesMutation,useGetPrevilegesMutation,useEditPropertyMutation } from '../../features/property/propertySlice';
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);
const apiUrl = process.env.REACT_APP_API_URL

function ApprovePropertyModal({ approvePropertyModal, toggleApproveModal, propertyDetail }) {
  const [amenities, setAmenities] = useState([]);
  const [previleges, setPrivileges] = useState([]);
  const[getAmenities,{isLoading}]=useGetAmenitiesMutation()
  const [getPrevileges,{isLoading:isLoadingPrevileges}]=useGetPrevilegesMutation()
  const [editProperty, { isLoading: isEditing }] = useEditPropertyMutation();
  const [editProperty2, { isLoading: isEditing2 }] = useEditPropertyMutation();


  const [files, setFiles] = useState([]);
  const [other_Files, setOtherFiles] = useState([]);
  const navigate=useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    image: "",
    user: "",
    slug:"",
    category: [],
    description: "",
    start_date: "",
    end_date: "",
    address: "",
    price: "",
    status:"",
    lat:"",
    lng: "",
    map_link: "",
    bedrooms: "",
    bathrooms: "",
    parking_spots: "",
    minimum_stay_period:"",
    pricing:[],
    amenities:[],
    previleges:[],
    
    region:"",




  
   
    

  });

  useEffect(() => {
    if (propertyDetail) {

      console.log(propertyDetail)
      setFormData({
        title: propertyDetail.title,
        image: propertyDetail.image,
        user: propertyDetail.user,
        category: propertyDetail.category,
        slug: propertyDetail.slug,
        description: propertyDetail.description,
        start_date: propertyDetail.start_date,
        end_date: propertyDetail.end_date,
        address: propertyDetail.address,
        price: propertyDetail.price,
        city:propertyDetail.city,
        status:propertyDetail.status,
        lat:propertyDetail.latitude,
        lng: propertyDetail.longitude,
        map_link: propertyDetail.map_link,
        bedrooms:propertyDetail.bedroom,
        bathrooms: propertyDetail.bathroom,
        parking_spots: propertyDetail.parking_spot,
        pricing:propertyDetail.pricing,
        amenities: propertyDetail.amenity.map(amenity => amenity.id),
        previleges:propertyDetail.privilege.map(previlege => previlege.id),
        region:propertyDetail.region,
        service_fee:propertyDetail.service_fee,
        listing_property_as:propertyDetail.listing_property_as,
        minimum_period:propertyDetail.pricing.max_days_allowed

    

      });

      console.log(propertyDetail)
      // Convert image URL to a File object
      console.log(propertyDetail)
      console.log(propertyDetail.files)
      const image_url=propertyDetail.files.replace(apiUrl,"")

      
      fetch(`/${image_url}`)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'first_image.jpg', { type: blob.type });
          
          setFiles([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });


        propertyDetail.other_files.map((file,index) => {
          console.log(file.files);
          const image_url=file.files.replace(apiUrl,"")

          fetch(`/${image_url}`)
            .then(res => res.blob())
            .then(blob => {
              const newFile = new File([blob],  `other_image${index}.jpg`, { type: blob.type });
              console.log(newFile);
              setOtherFiles(prevFiles => [
                ...prevFiles,
                {
                  source: newFile,
                  options: {
                    type: 'local'
                  }
                }
              ]);
            });

            
        });
        
    }
  }, [propertyDetail]);

  const formatDateTime = (dateString) => {
    // Create a new Date object from the dateString
    const date = new Date(dateString);
  
    // Format the date and time components to ensure two digits
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Return the formatted date string in the required format
    return `${year}-${month}-${day}  ${hours}:${minutes}:${seconds}`;
  };
  

  const handleGetAmenities=async ()=>{
    try{

      const response=await getAmenities().unwrap();
      console.log(response);
      setAmenities(response);

    }catch(e){
      console.log(e);
    }
  }

  const handleGetPrevileges=async ()=>{
    try{

      const response=await getPrevileges().unwrap();
      console.log(response);
      setPrivileges(response);

    }catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    handleGetAmenities();
    handleGetPrevileges();
  }, []);

  


  



  
  const handleApprove = async () => {
  

          const id=propertyDetail.id
          


      try {
        const variables={
          "status":"approved"
        }
         const response =await editProperty({id, body:variables}).unwrap();
         console.log("reponse",response)
         toggleApproveModal()
         
        
      } catch (error) {
        console.error('Error updating property:', error);
      }
    
  };


  
  const handleDecline = async () => {
  
    const id=propertyDetail.id
          


    try {
      const variables={
        "status":"declined"
      }
       const response =await editProperty({id, body:variables}).unwrap();
       console.log("reponse",response)
       toggleApproveModal()
       
      
    } catch (error) {
      console.error('Error updating property:', error);
    }
    
      

  
};
  return (
    <Modal size='lg' className='modal-content' isOpen={approvePropertyModal} toggle={toggleApproveModal}>
      <div className="modal-header">
        <h3 className="card-title">{propertyDetail && propertyDetail.title}</h3>
        <button type="button" className="close" onClick={toggleApproveModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className='p-2'>
        <div className='modal-body'>
          <div className="row border p-2 rounded-sm">
          <div className="col-md-6">
              <label>User <span className='text-danger'>*</span></label>
              <input type="text" name='user' className="form-control" required placeholder="" value={formData && formData.user.first_name+" "+formData.user.last_name} disabled />
            </div>
            
          
            <div className="col-md-6">
              <label>Title <span className='text-danger'>*</span></label>
              <input type="text" name='name' className="form-control" required placeholder="" value={formData && formData.title} disabled />
            </div>

            <div className='col-md-6'>
            <label>You are listing as?<span className='text-danger'>*</span></label>
            <select
                name='listing_property_as'
                className="form-control"
                required
                disabled
                value={propertyDetail &&propertyDetail.listing_property_as}
             
            >
                <option value="">Select Category</option>
                <option value="A private property">A private property</option>
                <option value="A registered business">A registered business</option>
            </select>
        </div>
            <div className="col-md-6 ">
              <label>categories <span className='text-danger'>*</span></label>
              <select multiple type="text" name='categories' disabled className="form-control" required placeholder="" >
              {propertyDetail && propertyDetail.category.map((category, index) => (
                  <option key={index} selected value={category.id}>{category.name}</option>
                ))}

              </select>
            </div>
          </div>
         
          <div className="row border p-2 rounded-sm mt-5">
            <div className="col-12">
              <label>Image <span className='text-danger'>*</span></label>
              
              <FilePond
                files={files}
                allowMultiple={false}
                disabled
                name="avatar_url"
                stylePanelLayout="compact"
                styleButtonRemoveItemPosition="left"
                styleButtonProcessItemPosition="right"
                styleLoadIndicatorPosition="right"
                styleProgressIndicatorPosition="right"
                styleButtonRemoveItemAlign={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                onupdatefiles={setFiles}
              />
            </div>


          </div>




          <div className="row border p-2 rounded-sm mt-5">
          <div className="col-12">
              <label>Address<span className='text-danger'>*</span></label>
              <input type="text" name='address' className="form-control" required placeholder="" value={formData && formData.address} disabled />
            </div>
            <div className="col-12">
                          <label>City<span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='city'
                            className="form-control"
                            required
                            placeholder=""
                            disabled
                            value={formData&&formData.city}
                           
                          />
                          </div>
            <div className="col-12">
              <label>Region<span className='text-danger'>*</span></label>
              <input type="text" name='address' className="form-control" required placeholder="" value={formData && formData.region} disabled />
            </div>

            <div className="col-12">
                          <label>Map Link<span className='text-danger'>*</span></label>
                          <input
                            type="url"
                            name='map_link'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData&&formData.map_link}
                            disabled
                          />
                          </div>
            <div className="col-md-6">
              <label>latitude <span className='text-danger'>*</span></label>
              <input type="text" name='lat' className="form-control" required placeholder="" value={formData && formData.lat} disabled />
            </div>
            <div className="col-md-6">
              <label>longitude <span className='text-danger'>*</span></label>
              <input type="text" name='lng' className="form-control" required placeholder="" value={formData && formData.lng} disabled />
            </div>

            <div className="col-md-6">
              <label>bedroom <span className='text-danger'>*</span></label>
              <input type="text" name='bedroom' className="form-control" required placeholder="" value={formData && formData.bedrooms} disabled />
            </div>

            <div className="col-md-6">
              <label>bathroom <span className='text-danger'>*</span></label>
              <input type="text" name='bathroom' className="form-control" required placeholder="" value={formData && formData.bathrooms} disabled />
            </div>

            <div className="col-md-6">
              <label>Parking Spot<span className='text-danger'>*</span></label>
              <input type="text" name='parking_spot' className="form-control" required placeholder="" value={formData && formData.parking_spots} disabled />
            </div>

            <div className="col-md-6">
              <label>Minimum Stay Period<span className='text-danger'>*</span></label>
              <input type="text" name='map_link' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].max_days_allowed +" days"} disabled />
            </div>
            <div className='col-12'>

            
            </div>

          </div>

          {formData.service_fee&&formData.service_fee.map((service, index) => (
    <div key={service.id} className="row border p-2 rounded-sm mt-5">
        <div className='col-md-4'>
            <label>Service<span className='text-danger'>*</span></label>
            <input
                type="text"
                name='service'
                className="form-control"
                required
                placeholder="Service"
                value={service.service}
                disabled
            />
        </div>
        <div className='col-md-4'>
            <label>Category<span className='text-danger'>*</span></label>
            <select
                name='category'
                className="form-control"
                required
                value={service.category}
                disabled
            >
                <option value="">Select Category</option>
                <option value="non-refundable">Non-Refundable</option>
                <option value="refundable">Refundable</option>
            </select>
        </div>
        <div className='col-md-4'>
            <label>Charge Per Day<span className='text-danger'>*</span></label>
            <input
                type="number"
                step="0.01"
                name='charge_per_day'
                className="form-control"
                required
                placeholder="Charge Per Day"
                value={service.charge_per_day}
                disabled
            />
        </div>
        <div className='col-md-4'>
            <label>Charge Per Week<span className='text-danger'>*</span></label>
            <input
                type="number"
                step="0.01"
                name='charge_per_week'
                className="form-control"
                required
                placeholder="Charge Per Week"
                value={service.charge_per_week}
                disabled
            />
        </div>
        <div className='col-md-4'>
            <label>Charge Per Month<span className='text-danger'>*</span></label>
            <input
                type="number"
                step="0.01"
                name='charge_per_month'
                className="form-control"
                required
                placeholder="Charge Per Month"
                value={service.charge_per_month}
                disabled
            />
        </div>
    </div>
))}

          <div className="row border p-2 rounded-sm mt-5">
            <div className='col-md-6'>
            <label>Currency<span className='text-danger'>*</span></label>
            <input type="text" name='pricing' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].currency} disabled />

            </div>

            <div className='col-md-6'>
            <label>Price<span className='text-danger'>*</span></label>
            <input type="text" name='pricing' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].price} disabled />

            </div>

            <div className='col-md-6'>
            <label>Discount One Week<span className='text-danger'>*</span></label>
            <input type="text" name='pricing' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].discount_one_week} disabled />

            </div>

            <div className='col-md-6'>
            <label>Discount Two Weeks<span className='text-danger'>*</span></label>
            <input type="text" name='pricing' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].discount_two_week} disabled />

            </div>
            <div className='col-md-6'>
            <label>Discount One Month<span className='text-danger'>*</span></label>
            <input type="text" name='pricing' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].discount_one_month} disabled />

            </div>

            <div className='col-md-6'>
            <label>Party Allowed<span className='text-danger'>*</span></label>
            <input type="text" name='pricing' className="form-control" required placeholder="" value={formData.pricing[0] && formData.pricing[0].is_party_inclusive?"Allowed":"Not Allowed"} disabled />

            </div>
          </div>

          <div className="row border p-2 rounded-sm mt-5">
            <div className="col-md-6">
              <label>Description</label>
              <textarea className="form-control" disabled name="description" rows="3" value={formData && formData.description}></textarea>
            </div>
            <div className="col-md-6"> 
            <label>Amenities</label>
            {
              amenities&&amenities.map((amenity,index)=>{
                return (
                

                  <div key={index} class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck2" checked={formData.amenities&&formData.amenities.includes(amenity.id)}/>
                                    <label class="custom-control-label" for="customCheck2">{amenity.name}</label>
                                 </div>
                )
              })
            }

            </div>
            <div className="col-md-6">
            <label>Amenities</label>
            {
              previleges&&previleges.map((previlege,index)=>{
                return (
                

                  <div key={index} class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck2" checked={formData.previleges&&formData.previleges.includes(previlege.id)}/>
                                    <label class="custom-control-label" for="customCheck2">{previlege.name}</label>
                                 </div>
                )
              })
            }


            </div>
          </div>
       
        <div className="row border p-2 rounded-sm mt-5">

     
            

        <div className='col-md-6'>
            <label>Currency<span className='text-danger'>*</span></label>
            <select className="form-control my-4" required name="icon" value={formData&& formData.status} disabled>
            <option  selected={formData&&formData.status==="draft"} value="draft">
                            Draft
                        </option>
                                            <option   selected={formData&&formData.status==="approved"} value="approved">
                            Approved
                        </option>
                                            <option value="pending" selected={formData&&formData.status==="pending"} >
                            Pending
                        </option>
                                            <option value="rejected" selected={formData&&formData.status==="rejected"} >
                            Rejected
                        </option>
                                            <option value="expired" selected={formData&&formData.status==="expired"} >
                            Expired
                        </option>
                        </select>

       

            </div>
        </div>


        <div className="row border p-2 rounded-sm mt-5">
        <div className='col-12'> 
        <label >Image <span className='text-danger'>*</span></label>

        </div>

        {
          other_Files&&other_Files.map((file,index)=>{
            return (
              <div key={index} className='col-md-6 border p-2 rounded-sm '>
              <FilePond
                files={[file]}
                allowMultiple={false}
                disabled
                name="avatar_url"
                stylePanelLayout="compact"
                styleButtonRemoveItemPosition="left"
                styleButtonProcessItemPosition="right"
                styleLoadIndicatorPosition="right"
                styleProgressIndicatorPosition="right"
                styleButtonRemoveItemAlign={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                
              />
            


        </div>
            )
          })
        }


       
        </div>
        <div className='modal-footer d-flex justify-content-start'>
        {
            isEditing?
            <button className="btn iq-bg-success"> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Approving... </button>

            :            <button className="btn iq-bg-success" onClick={handleApprove}>Approve </button>




        }
{
    isEditing2?

    <button className="btn iq-bg-danger" ><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Declining... </button>


    :
    <button className="btn iq-bg-danger" onClick={handleDecline}>Decline </button>


}
          <button className="btn iq-bg-secondary" onClick={toggleApproveModal}>Close </button>

        </div>
      </div>
      </div>
    </Modal>
  );
}

export default ApprovePropertyModal;
