import { apiSlice } from "../../app/api/apiSlice";



export const propertyApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({

        getCategories:builder.mutation({
            query:()=>({
                url:'admin/property/category/',
                method: 'GET',
                
            })
        }),

        addCategories:builder.mutation({
            query:(body)=>({
                url:'admin/property/category/',
                method: 'POST',
                body:{...body}
                
            })
        }),
        viewCategoryDetail:builder.mutation({
            query:(id)=>({
                url:`admin/property/category/${id}/`,
                method: 'GET',

              
                
            })
        }),

        


        editCategory:builder.mutation({
            query:({id,body})=>({
                url:`admin/property/category/${id}/`,
                method: 'PUT',
                body:{...body}

              
                
            })
        }),

        deleteCategory:builder.mutation({
            query:(id)=>({
                url:`admin/property/category/${id}/`,
                method: 'DELETE'
                

              
                
            })
        }),

        getProperties:builder.mutation({
            query:(params)=>({
                url:'admin/property/list/' ,
                method: 'GET',
                params:params,
                
            })
        }),
        getPropertyDetail:builder.mutation({
            query:(id)=>({
                url:`admin/property/${id}/`,
                method: 'GET',
                
            })
        }),

        addProperty:builder.mutation({
            query:(formData)=>({
                url:`admin/property/`,
                method: 'POST',
                body:formData,
                formData:true,


                
            })
        }),
        editProperty:builder.mutation({
            query:({id,body})=>({
                url:`admin/property/${id}/`,
                method: 'PATCH',
                body:{...body},
              


                
            })
        }),

        deleteProperty:builder.mutation({
            query:(id)=>({
                url:`admin/property/${id}/`,
                method: 'DELETE',
      
            })
        }),
        getAmenities:builder.mutation({
            query:()=>({
                url:'admin/property/amenities/',
                method: 'GET',
                
            })
        }),
        addAmenity:builder.mutation({
            query:(body)=>({
                url:'admin/property/amenities/',
                method: 'POST',
                body:{...body}
                
            })
        }),

        editAmenity:builder.mutation({
            query:({id,body})=>({
                url:`admin/property/amenities/${id}/`,
                method: 'PUT',
                body:{...body}

              
                
            })
        }),
        deleteAmenity:builder.mutation({
            query:(id)=>({
                url:`admin/property/amenities/${id}/`,
                method: 'DELETE'
                

              
                
            })
        }),

        
        getPrevileges:builder.mutation({
            query:()=>({
                url:'admin/property/privilege/',
                method: 'GET',
                
            })
        }),
        

        addPrevilege:builder.mutation({
            query:(body)=>({
                url:'admin/property/privilege/',
                method: 'POST',
                body:{...body}
                
            })
        }),

        editPrevilege:builder.mutation({
            query:({id,body})=>({
                url:`admin/property/privilege/${id}/`,
                method: 'PUT',
                body:{...body}

              
                
            })
        }),


        deletePeivilege:builder.mutation({
            query:(id)=>({
                url:`admin/property/privilege/${id}/`,
                method: 'DELETE'
                

              
                
            })
        }),



        getFAQs:builder.mutation({
            query:()=>({
                url:'admin/enquiry/faq/',
                method: 'GET',
                
            })
        }),


        addFAQ:builder.mutation({
            query:(body)=>({
                url:'admin/enquiry/faq/',
                method: 'POST',
                body:{...body}
                
            })
        }),



        editFAQ:builder.mutation({
            query:({id,body})=>({
                url:`admin/enquiry/faq/${id}/`,
                method: 'PUT',
                body:{...body}

              
                
            })
        }),

        deleteFAQ:builder.mutation({
            query:(id)=>({
                url:`admin/enquiry/faq/${id}/`,
                method: 'DELETE'
                

              
                
            })
        }),

        getEvents:builder.mutation({
            query:(params)=>({
                url:'admin/enquiry/event/',
                method: 'GET',
                params:params
                
            })
        }),

        getEventsDetail:builder.mutation({
            query:(id)=>({
                url:`admin/enquiry/event/${id}/`,
                method: 'GET',
                
            })
        }),


        getContacts:builder.mutation({
            query:()=>({
                url:'admin/enquiry/contact/',
                method: 'GET',
                
                
            })
        }),

        deleteContacts:builder.mutation({
            query:(id)=>({
                url:`admin/enquiry/contact/${id}/`,
                method: 'DELETE'
                

              
                
            })
        }),


        deleteEvent:builder.mutation({
            query:(id)=>({
                url:`admin/enquiry/event/${id}/`,
                method: 'DELETE'
                

              
                
            })
        }),
        
        addEvent:builder.mutation({
            query:(formData)=>({
                url:'admin/enquiry/event/',
                method: 'POST',
                body:formData,
                formData:true
                
            })
        }),
        
    editEvent:builder.mutation({
            query:({id,formData})=>({
                url:`admin/enquiry/event/${id}/ `,
                method:'PATCH',
                body:formData,
                formData:true
                
            })
        }),

        getSettings:builder.mutation({
            query:()=>({
                url:`admin/manage-site/settings/ `,
                method:'GET',
               
                
            })
        }),

        editSettings:builder.mutation({
            query:({formData})=>({
                url:`admin/manage-site/settings/ `,
                method:'PATCH',
                body:formData,
                formData:true
                
            })
        }),

        getNewsLetter:builder.mutation({
            query:(params)=>({
                url:`admin/manage-site/newsletter/`,
                method:'GET',
                params:params
               
                
            })
        }),


        addNewsLetter:builder.mutation({
            query:(body)=>({
                url:'admin/manage-site/newsletter/',
                method: 'POST',
                body:{...body}
               
                
            })
        }),
        

        editNewsLetter:builder.mutation({
            query:({id,body})=>({
                url:`admin/manage-site/newsletter/${id}/`,
                method: 'PATCH',
                body:{...body}
               
                
            })
        }),


        deleteNewsLetter:builder.mutation({
            query:(id)=>({
                url:`admin/manage-site/newsletter/${id}/`,
                method: 'DELETE',
              
               
                
            })
        }),
       

        addPostTags:builder.mutation({
            query:(body)=>({
                url:'admin/blog/tag/',
                method: 'POST',
                body:{...body},
            
               
                
            })
        }),

        getPostTags:builder.mutation({
            query:(body)=>({
                url:'admin/blog/tag/',
                method: 'GET',
                
               
                
            })
        }),

                deletePostTags:builder.mutation({
            query:(id)=>({
                url:`admin/blog/tag/${id}/`,
                method: 'DELETE',
                
               
                
            })
        }),

        editPostTags:builder.mutation({
            query:({id,body})=>({
                url:`admin/blog/tag/${id}/`,
                method:'PATCH',
                body:{...body},
           
                
            })
        }),

        addPostCategory:builder.mutation({
            query:(body)=>({
                url:'admin/blog/category/',
                method: 'POST',
                body:{...body}
               
                
            })
        }),
        getAllPostCategories:builder.mutation({
            query:()=>({
                url:`admin/blog/category/`,
                method:'GET',
               
                
            })
        }),
       
        editPostCategory:builder.mutation({
            query:({id,body})=>({
                url:`admin/blog/category/${id}/`,
                method: 'PATCH',
                body:{...body}
               
                
            })
        }),

        deletePostCategory:builder.mutation({
            query:(id)=>({
                url:`admin/blog/category/${id}/`,
                method: 'DELETE',
              
               
                
            })
        }),

        addPost:builder.mutation({
            query:(formData)=>({
                url:'admin/blog/',
                method: 'POST',
                body:formData,
                formData:true
                
            })
        }),

        viewPostDetail:builder.mutation({
            query:(id)=>({
                url:`admin/blog/${id}`,
                method: 'GET',
             
                
            })
        }),

        editPost:builder.mutation({
            query:({id,formData})=>({
                url:`admin/blog/${id}/`,
                method: 'PATCH',
                body:formData,
                formData:true
                
            })
        }),
        getAllPost:builder.mutation({
            query:()=>({
                url:`admin/blog/`,
                method:'GET',
               
                
            })
        }),

        deletePost:builder.mutation({
            query:(id)=>({
                url:`admin/blog/${id}/`,
                method: 'DELETE',
              
               
                
            })
        }),




        getProfile:builder.mutation({
            query:()=>({
                url:`auth/profile/`,
                method:'GET',
               
                
            })
        }),



        
        getUsers:builder.mutation({
            query:(params)=>({
                url:`admin/user/`,
                method:'GET',
                params:params
               
                
            })
        }),
        getUserDetail:builder.mutation({
            query:(id)=>({
                url:`admin/user/${id}`,
                method:'GET',
               
                
            })
        }),
        
        deleteUser:builder.mutation({
            query:(id)=>({
                url:`admin/user/${id}/`,
                method:'DELETE',
               
                
            })
        }),

        editUser:builder.mutation({
            query:({id,formData})=>({
                url:`admin/user/${id}/`,
                method:'PATCH',
                body:formData,
                formData:true
                            
                
            })
        }),

        
        editUser2:builder.mutation({
            query:({id,body})=>({
                url:`admin/user/${id}/`,
                method:'PATCH',
                body:body,
                            
                
            })
        }),


        registerUser:builder.mutation({
            query:(formData)=>({
                url:`auth/register/`,
                method:'POST',
                body:formData,
                formData:true
                            
                
            })
        }),
        addSiteLayout:builder.mutation({
            query:(formData)=>({
                url:`admin/manage-site/carousel/`,
                method:'POST',
                body:formData,
                formData:true
               
                
            })
        }),
        editSiteLayout:builder.mutation({
            query:({id,formData})=>({
                url:`admin/manage-site/carousel/${id}/`,
                method:'PATCH',
                body:formData,
                formData:true
               
                
            })
        }),


        getSiteLayouts:builder.mutation({
            query:()=>({
                url:`admin/manage-site/carousel/`,
                method:'GET',
               
                
            })
        }),


        deleteSiteLayout:builder.mutation({
            query:(id)=>({
                url:`admin/manage-site/carousel/${id}`,
                method:'DELETE',
               
                
            })
        }),

      
        getUserBooking:builder.mutation({
            query:(params)=>({
                url:`admin/user/booking/`,
                method:'GET',
                params:params
               
                
            })
        }),
              
        getDashboardDetails:builder.mutation({
            query:(params)=>({
                url:`admin/manage-site/dashboard-report/`,
                method:'GET',
                params:params
               
                
            })

        }),

        

         getFinancialDetails:builder.mutation({
            query:(params)=>({
                url:`admin/manage-site/financial-report/`,
                method:'GET',
                params:params
               
                
            })
        }),
           

            
        getReportDetails:builder.mutation({
            query:(params)=>({
                url:`admin/manage-site/report/`,
                method:'GET',
                params:params
               
                
            })
        }),

        
        addEventCategory:builder.mutation({
            query:(formData)=>({
                url:`admin/enquiry/event-category/`,
                method:'POST',
                body:formData,
                formData:true
                
            })
        }),

        editEventCategory:builder.mutation({
            query:({id,formData})=>({
                url:`admin/enquiry/event-category/${id}/`,
                method:'PATCH',
                body:formData,
                formData:true
               
                
            })
        }),

        deleteEventCategory:builder.mutation({
            query:(id)=>({
                url:`admin/enquiry/event-category/${id}/`,
                method:'DELETE',
               
                
            })
        }),

        getEventCategory:builder.mutation({
            query:(params)=>({
                url:`admin/enquiry/event-category/`,
                method:'GET',
                params:params
          
            })
        }),

        addPromoCode:builder.mutation({
            query:(body)=>({
                url:`admin/user/promocode/`,
                method:'POST',
                body:{...body},
              
                
            })
        }),

        getPromoCode:builder.mutation({
            query:(params)=>({
                url:`admin/user/promocode/`,
                method:'GET',
                params:params
             
              
                
            })
        }),

        editPromoCode:builder.mutation({
            query:()=>({
                url:`admin/user/promocode/`,
                method:'GET',

             
              
                
            })
        }),


       

        


        


    })

})


export const {
    
useGetCategoriesMutation,
useAddCategoriesMutation,
useViewCategoryDetailMutation,
useEditCategoryMutation,
useDeleteCategoryMutation,
useGetAmenitiesMutation,
useAddAmenityMutation,
useEditAmenityMutation,
useDeleteAmenityMutation,
useGetPrevilegesMutation,
useAddPrevilegeMutation,
useEditPrevilegeMutation,
useDeletePeivilegeMutation,
useGetFAQsMutation,
useAddFAQMutation,
useEditFAQMutation,
useDeleteFAQMutation,
useGetEventsMutation,
useGetContactsMutation,
useDeleteContactsMutation,
useGetEventsDetailMutation,
useDeleteEventMutation,
useAddEventMutation,
useEditEventMutation,
useGetPropertiesMutation,
useGetSettingsMutation,
useEditSettingsMutation,
useGetNewsLetterMutation,
useEditNewsLetterMutation,
useAddNewsLetterMutation,
useDeleteNewsLetterMutation,
useAddPostCategoryMutation,
useGetAllPostCategoriesMutation,
useEditPostCategoryMutation,
useDeletePostCategoryMutation,
useGetProfileMutation,
useGetUsersMutation,
useGetPostTagsMutation,
useAddPostTagsMutation,
useEditPostTagsMutation,
useDeletePostTagsMutation,
useAddPostMutation,
useEditPostMutation,
useDeletePostMutation,
useGetAllPostMutation,
useGetPropertyDetailMutation,
useEditPropertyMutation,
useDeletePropertyMutation,
useAddPropertyMutation,
useViewPostDetailMutation,
useAddSiteLayoutMutation,
useEditSiteLayoutMutation,
useGetSiteLayoutsMutation,
useDeleteSiteLayoutMutation,
useGetUserDetailMutation,
useGetUserBookingMutation,
useDeleteUserMutation,
useEditUserMutation,
useRegisterUserMutation,
useGetDashboardDetailsMutation,
useGetReportDetailsMutation,
useGetFinancialDetailsMutation,
useGetEventCategoryMutation,
useDeleteEventCategoryMutation,
useEditEventCategoryMutation,
useAddEventCategoryMutation,
useAddPromoCodeMutation,
useGetPromoCodeMutation,
useEditUser2Mutation
}=propertyApiSlice;