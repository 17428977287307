import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);
const apiUrl = process.env.REACT_APP_API_URL

function ViewEventModal({ viewEventModal, toggleViewModal, eventDetail }) {
  const [files, setFiles] = useState([]);
  const [other_images,setOtherImages] = useState([])
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    slug: "",
    description: "",
    start_date: "",
    end_date: "",
    location: "",
    price: "",
    status:"",

    

  });

  useEffect(() => {
    if (eventDetail) {
      setFormData({
        title: eventDetail.title,
        image: eventDetail.featured_image,
        slug: eventDetail.slug,
        description: eventDetail.description,
        start_date: eventDetail.start_date,
        end_date: eventDetail.end_date,
        location: eventDetail.location,
        price: eventDetail.price,
        status:eventDetail.event_type,
      });
console.log(eventDetail.category)
      // Convert image URL to a File object
      const url_image = eventDetail.featured_image.replace(apiUrl,"")
      console.log(url_image);
      fetch( `/${url_image}`)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'image.jpg', { type: blob.type });
          setFiles([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });
    }
if (eventDetail&&eventDetail.other_image.length > 0){

    eventDetail.other_image.map((file,index) => {

      const url_image=file.image.replace(apiUrl,"")
      
      fetch(`/${url_image}`)
        .then(res => res.blob())
        .then(blob => {
          const newFile = new File([blob],  `other_image${index}.jpg`, { type: blob.type });
          console.log(newFile);
          setOtherImages(prevFiles => [
            ...prevFiles,
            {
              source: newFile,
              options: {
                type: 'local'
              }
            }
          ]);
        });

        
    });
  }
  }, [eventDetail]);

  const formatDateTime = (dateString) => {
    // Create a new Date object from the dateString
    const date = new Date(dateString);
  
    // Format the date and time components to ensure two digits
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Return the formatted date string in the required format
    return `${year}-${month}-${day}  ${hours}:${minutes}:${seconds}`;
  };
  

  return (
    <Modal size='lg' className='modal-content' isOpen={viewEventModal} toggle={toggleViewModal}>
      <div className="modal-header">
        <h3 className="card-title">{eventDetail && eventDetail.title}</h3>
        <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className='p-2'>
        <div className='modal-body'>
          <div className="row border p-2 rounded-sm">
            <div className="col">
              <label>Title <span className='text-danger'>*</span></label>
              <input type="text" name='name' className="form-control" required placeholder="" value={formData && formData.title} disabled />
            </div>
            <div className="col">
              <label>Slug <span className='text-danger'>*</span></label>
              <input type="text" name='slug' className="form-control" required placeholder="" value={formData && formData.slug} disabled />
            </div>
          </div>

          <div className="row border p-2 rounded-sm mt-5">
            <div className="col-12">
              <label>Image <span className='text-danger'>*</span></label>
              
              <FilePond
                files={files}
                allowMultiple={false}
                disabled
                name="avatar_url"
                stylePanelLayout="compact"
                styleButtonRemoveItemPosition="left"
                styleButtonProcessItemPosition="right"
                styleLoadIndicatorPosition="right"
                styleProgressIndicatorPosition="right"
                styleButtonRemoveItemAlign={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                onupdatefiles={setFiles}
              />
            </div>

            <div className="col-md-6">
              <label>Start Date<span className='text-danger'>*</span></label>
              <input type="text" name='name' className="form-control" required placeholder="" value={formData &&  formatDateTime(formData.start_date)} disabled />
            </div>
            <div className="col-md-6 ">
              <label>End Date <span className='text-danger'>*</span></label>
              <input type="text" name='slug' className="form-control" required placeholder="" value={formData && formatDateTime(formData.end_date)} disabled />
            </div>

          </div>




          <div className="row border p-2 rounded-sm mt-5">
          <div className="col-md-6">
              <label>Location<span className='text-danger'>*</span></label>
              <input type="text" name='name' className="form-control" required placeholder="" value={formData && formData.location} disabled />
            </div>
            <div className="col-md-6">
              <label>Price <span className='text-danger'>*</span></label>
              <input type="text" name='slug' className="form-control" required placeholder="" value={formData && formData.price} disabled />
            </div>

            <div className='col-md-6'>

            <select className="form-control my-4" required name="icon" value={formData&& formData.status} disabled>
                <option selected disabled={true} hidden={true} value="">Select an option</option>
            
                <option value="active">
                            Active
                        </option>
                                            <option value="inactive">
                            Inactive
                        </option>
                        </select>
            </div>
            <div className="col-md-6 ">
              <label>categories <span className='text-danger'>*</span></label>
              <select multiple type="text" name='categories' disabled className="form-control" required placeholder="" >
              {eventDetail && eventDetail.category_detail.map((category, index) => (
                  <option key={index} selected value={category.id}>{category.title}</option>
                ))}

              </select>
            </div>
          </div>

          <div className="row border p-2 rounded-sm mt-5">
            <div className="col-12">
              <label>Description</label>
              <textarea className="form-control" disabled name="description" rows="3" value={formData && formData.description}></textarea>
            </div>
          </div>
<div className='row mt-5 '>
          {
          other_images&&other_images.map((file,index)=>{
            return (
              <div key={index} className='col-md-6 border p-2 rounded-sm '>
              <FilePond
                files={[file]}
                allowMultiple={false}
                disabled
                name="avatar_url"
                stylePanelLayout="compact"
                styleButtonRemoveItemPosition="left"
                styleButtonProcessItemPosition="right"
                styleLoadIndicatorPosition="right"
                styleProgressIndicatorPosition="right"
                styleButtonRemoveItemAlign={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                
              />
            


        </div>
            )
          })
        }
        </div>
        </div>

        <div className='modal-footer'>
          <button className="btn iq-bg-primary" onClick={toggleViewModal}>Close</button>
        </div>
      </div>
    </Modal>
  );
}

export default ViewEventModal;
