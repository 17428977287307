import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { useDeleteUserMutation} from '../../features/property/propertySlice';
import { useEditUser2Mutation } from '../../features/property/propertySlice';
function SuspendUserModal({suspendUserModal,toggleSuspendModal,userDetail}) {

    const [deletedit,{isLoading}]=useEditUser2Mutation();

    const handleSuspendUser =async() =>{

        try{
          if (userDetail.is_suspended){
            const response =await deletedit({id:userDetail.id,body:{is_suspended:'false'}}).unwrap();

            console.log(response)
          }
          else{
            const response =await deletedit({id:userDetail.id,body:{is_suspended:'true'}}).unwrap();

            console.log(response)
            
          }

         
            toggleSuspendModal()
         
        }catch(e){
            console.log(e)
         
        }
    }
  return (
    <Modal  className='modal-content' isOpen={suspendUserModal} toggle={toggleSuspendModal}>
<div className="modal-header">
      <button type="button" className="close" onClick={toggleSuspendModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div className='p-2 ' >
    {
      userDetail &&userDetail.is_suspended ?
      
      <div className='modal-body'>

   

         <h4>Are you sure you want to activate {userDetail &&userDetail.first_name +" "+userDetail.last_name}</h4>
       

     
      
      

</div>

      :
      
      <div className='modal-body'>

   

         <h4>Are you sure you want to suspend {userDetail &&userDetail.first_name +" "+userDetail.last_name}</h4>
         <p className='text-danger'>The user won't be able to perform some actions.</p>
       

     
      
      

</div>
    }

{
  userDetail &&userDetail.is_suspended ?
  <div className='modal-footer'>
         <button className="btn iq-bg-primary" onClick={toggleSuspendModal}>Cancel</button>

          
          {
            isLoading?
            <button type="submit" className="btn btn-success">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Restoring...</button>
            :
            <button onClick={handleSuspendUser} className="btn btn-success">Restore</button>
          }

         
        </div>
  :
  <div className='modal-footer'>
         <button className="btn iq-bg-primary" onClick={toggleSuspendModal}>Cancel</button>

          
          {
            isLoading?
            <button type="submit" className="btn btn-danger">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Suspending...</button>
            :
            <button onClick={handleSuspendUser} className="btn btn-danger">Suspend</button>
          }

         
        </div>

}

</div>
</Modal>
  )
}

export default SuspendUserModal