import React,{useRef, useState} from 'react'
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar'
import { Row, Col } from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useRegisterUserMutation } from '../../features/property/propertySlice';
import { useNavigate } from 'react-router-dom';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode
);


function AddNewPartnerPage() {
    const [files, setFiles] = React.useState([]);
    const [registeruser,{isLoading}]=useRegisterUserMutation()
    const [registeruser1,{isLoading:isLoading1}]=useRegisterUserMutation()
    const navigate = useNavigate();
    const [success,setSuccess]=useState();
    const [error,setError]=useState();

    const formRef=useRef(null)
    const [formData, setFormData] = React.useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        
        password: '',
        role:"partner",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();

        if (isValid) {
            try{
                console.log(formData)
                const formDataToSend = new FormData();
                files.forEach(file => formDataToSend.append('profile', file.file));
                Object.keys(formData).forEach(key => {
                    formDataToSend.append(key, formData[key]);
                });
             
                const response =await registeruser( formDataToSend);
                console.log(response)
                
                if (response?.error.status===400){
                    
                    setError(" Email has already been registered")
                    setSuccess()
                }
                else if (response?.error.status===401){
                    setError(" Promo Code Already Exists")
                    setSuccess()
                    
                }
                else{
                    setSuccess("Success");
                    setError()
                    setFormData(
                        {
                            first_name: '',
                            last_name: '',
                            phone_number: '',
                            email: '',
                           
                            password: '',
                            role:"partner",
                        }
                    )
                    navigate('/partner/list')
                }
               
            } catch (error) {
                
             
                setError("Try Again with Valid data")
                setSuccess()
            }
        } else {

            formRef.current.classList.add('was-validated');
        }
    }


    const handleSubmit1 = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();

        if (isValid) {
            try{
                console.log(formData)
                const formDataToSend = new FormData();
                files.forEach(file => formDataToSend.append('profile', file.file));
                Object.keys(formData).forEach(key => {
                    formDataToSend.append(key, formData[key]);
                });
             
                const response =await registeruser1( formDataToSend);
               
                if (response?.error.status===400){
                    
                    setError(" Email has already been registered")
                    setSuccess()
                }
                else if (response?.error.status===401){
                    setError(" Promo Code Already Exists")
                    setSuccess()
                }
                else{
                    setSuccess("Success");
                    setError()
                }
               
            } catch (error) {
                console.error(error);
                setError("Try Again with Valid data")
                setSuccess()
                setFormData(
                    {
                        first_name: '',
                        last_name: '',
                        phone_number: '',
                        email: '',
                        promo_code: '',
                        start_date: '',
                        end_date: '',
                        password: '',
                        role:"partner",
                    }
                )
            }
        } else {

            formRef.current.classList.add('was-validated');
        }
    }

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex flex-column justify-content-between mb-4'>
                                <div className='w-100'>
                                    <div className="">
                                        <h4 className="card-title">Add New Partner</h4>
                                    </div>
                                    <form  ref={formRef} className='w-100 needs-validation p-2 row' noValidate>
                               
                                        <div className='w-100'>
                                        {
                                            success?
                                            <div class="alert  alert-success" role="alert">
                              <div class="iq-alert-text"> <b>success! </b> Partner Added successfully</div>
                              <button type="button"  className="close text-dark" onClick={()=>{setSuccess()}} data-dismiss="alert" aria-label="Close">
                              <i class="ri-close-line"></i>
                              </button>
                           </div>
                                            :
                                            ""


                                        }

                                        {
                                            error?
                                            <div class="alert  alert-danger" role="alert">
                              <div class="iq-alert-text"> <b>Error! </b>{error}</div>
                              <button type="button"  className="close text-dark" onClick={()=>{setError()}} data-dismiss="alert" aria-label="Close">
                              <i class="ri-close-line"></i>
                              </button>
                           </div>
                                            :
                                            ""

                                        }
                              
                                            <div className="row border p-2 rounded-sm">
                                                <div className="col-12 mb-2">
                                                    <label>Profile Photo <span className='text-danger'>*</span></label>
                                                    <FilePond
                                                        files={files}
                                                        allowMultiple={false}
                                                        name="avatar_url"
                                                        stylePanelLayout="compact"
                                                        styleButtonRemoveItemPosition="left"
                                                        styleButtonProcessItemPosition="right"
                                                        styleLoadIndicatorPosition="right"
                                                        styleProgressIndicatorPosition="right"
                                                        styleButtonRemoveItemAlign={false}
                                                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                                        onupdatefiles={setFiles}
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>First Name <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name='first_name'
                                                        className="form-control"
                                                        required
                                                        placeholder=""
                                                        value={formData.first_name}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>Last Name <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name='last_name'
                                                        className="form-control"
                                                        required
                                                        placeholder=""
                                                        value={formData.last_name}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>Phone Number <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name='phone_number'
                                                        className="form-control"
                                                        required
                                                        placeholder=""
                                                        value={formData.phone_number}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>Email <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name='email'
                                                        className="form-control"
                                                        required
                                                        placeholder=""
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                               

                                                <div className="col-md-6 mb-2">
                                                    <label>Password <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="password"
                                                        name='password'
                                                        className="form-control"
                                                        required
                                                        placeholder=""
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className='col-12'>
                      <div className='mt-4'>
                       
                        {
                            isLoading?
                            <button className="btn btn-primary ">
                       <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Creating...
                        </button>

                            :

                            <button onClick={handleSubmit} className="btn btn-primary ">
                                Create
                        </button>

                        }


                        {
                            isLoading1?
                            <button className="btn btn-secondary  mx-2 ">
                       <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Creating...
                        </button>

                            :

                            <button onClick={handleSubmit1} className="btn btn-secondary mx-2 ">
                                Create & create another
                        </button>

                        }
                
                
                       
                      </div>
                    </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AddNewPartnerPage;
