import React ,{useState,useEffect} from 'react'
import { Modal } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';


registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode
  );
  const apiUrl=process.env.REACT_APP_API_URL
  

function ViewEventCategory({viewEventCategoryModal, toggleViewModal, eventCategoryDetail }) {
    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        slug: "",
        image:"",
      
        
    
      });
    
      useEffect(() => {
        if (eventCategoryDetail) {
          setFormData({
            title: eventCategoryDetail.title,
            image: eventCategoryDetail.image,
            slug: eventCategoryDetail.slug,

          });
    
          // Convert image URL to a File object

          const imageUrl=eventCategoryDetail.image.replace(apiUrl,"")
          fetch(`/${imageUrl}`)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], 'image.jpg', { type: blob.type });
              setFiles([
                {
                  source: file,
                  options: {
                    type: 'local'
                  }
                }
              ]);
            });
        }
      }, [eventCategoryDetail]);


  return (
    <Modal size='lg' className='modal-content' isOpen={viewEventCategoryModal} toggle={toggleViewModal}>
    <div className="modal-header">
      <h3 className="card-title">View Event category</h3>
      <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form  className='p-2 needs-validation' noValidate >
      <div className='modal-body'>


      



<div className="row border p-2 rounded-sm">
        
        <div className="col-12 mb-1 ">
          <label>Title <span className='text-danger'>*</span></label>
          <input type="text" name='title' className="form-control" required placeholder="" value={formData.title}  />
        </div>
        <div className="col-12 mb-1">
          <label>Slug <span className='text-danger'>*</span></label>
          <input type="text" name='slug'  className="form-control" required placeholder="" value={formData.slug}  />
        </div>

        <div className="col-12">
          <label>Image<span className='text-danger'>*</span></label>

          <FilePond
                        files={files}
                        allowMultiple={false}
                 
                        name="avatar_url"
                        stylePanelLayout="compact"
                        styleButtonRemoveItemPosition="left"
                        styleButtonProcessItemPosition="right"
                        styleLoadIndicatorPosition="right"
                        styleProgressIndicatorPosition="right"
                        styleButtonRemoveItemAlign={false}
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        onupdatefiles={setFiles}
                        
                      />
        </div>
      
      </div>
      
      </div>

      <div className='modal-footer'>
        
       
          <button type="button" onClick={toggleViewModal} className="btn btn-primary">Close</button>
        
       
      </div>
    </form>
  </Modal>
)
}

export default ViewEventCategory