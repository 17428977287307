import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import { useGetPropertiesMutation } from '../../features/property/propertySlice';
import AddPropertyCategoryModal from '../../components/property/category/addPropertyCategoryModal';
import ViewPropertyDetailModal from '../../components/property/viewPropertyDetailModal';
import EditPropertyCategoryModal from '../../components/property/category/editPopertyCategoryModal';
import DeletePropertyModal from '../../components/property/deletePropertyModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PropertyList() {
    const [getProperties, { isLoading }] = useGetPropertiesMutation();
    const [properties, setProperties] = useState([]);
    const [propertyDetail, setPropertyDetail] = useState(null);
    const [pending, setPending] = useState(true);

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Adjust this value as needed

    const handleGetProperties = async () => {
        try {
            const response = await getProperties().unwrap();
            setProperties(response.results);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetProperties();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    const filteredItems = properties.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
    );

    // Pagination logic
    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(filteredItems.length / rowsPerPage);

    const [propertyCategoryModal, setPropertyCategoryModal] = useState(false);
    const toggleModal = () => {
        setPropertyCategoryModal(!propertyCategoryModal);
        handleGetProperties();
    };

    const [viewPropertyModal, setViewPropertyCategoryModal] = useState(false);
    const [editPropertyCategoryModal, setEditPropertyCategoryModal] = useState(false);
    const [deletePropertyModal, setDeletePropertyCategoryModal] = useState(false);

    const toggleViewModal = () => {
        setViewPropertyCategoryModal(!viewPropertyModal);
    };

    const toggleEditModal = () => {
        setEditPropertyCategoryModal(!editPropertyCategoryModal);
        handleGetProperties();
    };

    const toggleDeleteModal = () => {
        setDeletePropertyCategoryModal(!deletePropertyModal);
        handleGetProperties();
    };

    const handleGetCategoryDetail = (row) => {
        setPropertyDetail(row);
        setViewPropertyCategoryModal(true);
    };

    const handleEditCategory = (row) => {
        setPropertyDetail(row);
        setEditPropertyCategoryModal(true);
    };

    const handleDeleteCategory = (row) => {
        setPropertyDetail(row);
        setDeletePropertyCategoryModal(true);
    };

    const columns = [
        {
            id: 'id',
            name: 'Image',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                    <img src={row.files} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
                </div>
            ),
            width: "fit-content", // Set width for Image column
        },
        {
            name: 'Title',
            selector: row => (
                <div>
                    <h5>{row.title}</h5>
                    {row.category.map((category, index) => {
                        return <span key={index} className='text-muted fs-xs mr-1'>{category.name}</span>
                    })}
                </div>
            ),
            width: '25%',
        },
        {
            name: 'Price',
            selector: row => (
                <div>
                    <span className="btn mb-1 iq-bg-primary">{row.pricing[0].currency} {row.pricing[0].price}</span>
                </div>
            ),
            width: '15%',
        },
        {
            name: 'Min Days',
            selector: row => (
                <div>
                    <span className="badge badge-pill badge-primary">{row.pricing[0].max_days_allowed}</span>
                </div>
            ),
            width: "fit-content",
        },
        {
            name: 'Address&Creation',
            selector: row => (
                <div>
                    <p>{row.address.length > 20 ? row.address.slice(0, 20) + "..." : row.address}</p>
                </div>
            ),
            width: '200px',
        },
        {
            name: 'Status',
            selector: row => (
                <div>
                    <p className='btn mb-1 iq-bg-primary'>{row.status}</p>
                </div>
            ),
            width: '120px',
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <Link className='text-primary action-link mx-3' size="sm" to={`/properties/edit/${row.id}`}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </Link>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width: "fit-content",
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Properties</h4>
                                <Link type="button" to="/properties/add" className="btn btn-primary btn-sm mr-2">New property</Link>
                                <AddPropertyCategoryModal propertyCategoryModal={propertyCategoryModal} toggleModal={toggleModal} />
                                <ViewPropertyDetailModal viewPropertyModal={viewPropertyModal} toggleViewModal={toggleViewModal} propertyDetail={propertyDetail} />
                                <EditPropertyCategoryModal editPropertyCategoryModal={editPropertyCategoryModal} toggleEditModal={toggleEditModal} propertyDetail={propertyDetail} />
                                <DeletePropertyModal deletePropertyModal={deletePropertyModal} toggleDeleteModal={toggleDeleteModal} propertyDetail={propertyDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={currentItems} // Use currentItems for pagination
                                            pagination
                                            paginationServer // Enable server-side pagination
                                            paginationTotalRows={filteredItems.length} // Total number of filtered items
                                            onChangePage={page => setCurrentPage(page)} // Update the current page
                                            onChangeRowsPerPage={newPerPage => {
                                                setRowsPerPage(newPerPage); 
                                                setCurrentPage(1); // Reset to the first page on rows per page change
                                            }}
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending} // Loading state
                                            progressComponent={<Skeleton count={5} />}
                                            noDataComponent={<div>No data available</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PropertyList;
