import React,{useState,useEffect} from 'react'
import DataTable from 'react-data-table-component'
import Skeleton from 'react-loading-skeleton'
import { format } from 'date-fns'
import { useGetPromoCodeMutation } from '../../features/property/propertySlice'


function PromoCodeTab({user_id}) {


const [promocodes,setPromoCodes]=useState([])
const [getpromocodes,{isLoading}]=useGetPromoCodeMutation()

const hadleGetPromoCode =async()=>{
    try{
      console.log(user_id)
      const response = await getpromocodes({user_id:user_id}).unwrap();
      console.log(response)
      setPromoCodes(response.results)
    }catch(error){
      console.log(error)
    }
  }


useEffect(()=>{
    hadleGetPromoCode();
},[]);



    const  columns=[

      {
         id: 'Name',
         name: 'Name',
         selector: row => <div className='text-wrap mb-0 my-1'><div className='h6 text-wrap'>{row.name}</div>
     
          </div>,
         sortable: true,
         reorder: true,
         width:"30%"
     },

     {
      name: 'Begin Date',
      selector: row => <div className='text-wrap'><span>{format(new Date(row.start_date), 'dd/MM/yyyy HH:mm')}</span>

      
      
       </div>,
      sortable: true,
      reorder: true,
      width:"20%"
  },

  {
    name: 'End Date',
    selector: row => <div className='text-wrap'><span>{format(new Date(row.end_date), 'dd/MM/yyyy HH:mm')}</span>

    <br/>

     </div>,
    sortable: true,
    reorder: true,
    width:"20%"
},

     {
      name: 'Percentage',
      selector: row => <div className='text-wrap'>{row.percentage}%</div>,
      sortable: true,
      reorder: true,
      width:"20%"
  },




      ]


      const [filterText, setFilterText] = useState('');
      const [selectedRows, setSelectedRows] = useState([]);


      const filteredItems = promocodes.filter(
        item => item.name.includes(filterText.toLowerCase())
      );



    


  return (
    <div class="iq-card">
                       
    <div class="iq-card-body">
       <ul class="nav nav-tabs justify-content-center" id="myTab-2" role="tablist">
          <li class="nav-item">
             <a class={ `nav-link active`}   id="home-tab-justify" data-toggle="tab"  style={{cursor:"pointer"}} role="tab" aria-controls="home" aria-selected="false">Promo Codes</a>
          </li>
        
       </ul>
       <div class="tab-content" id="myTabContent-3">
          <div class={`tab-pane fade active show`} id="home-justify" role="tabpanel" aria-labelledby="home-tab-justify">

          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className='text-primary d-flex align-content-center hover-icon'>
                    <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                  </div>
                  <div className="search-box d-flex justify-content-end">
                    <input type="text" className="text search-input" placeholder="Search By Name..."
                      value={filterText}
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ overflowX: 'auto' }}>
                  <div style={{ minWidth: '1100px' }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationServer
                     
                      selectableRows
                      selectableRowsComponentProps={{
                        type: 'checkbox',
                      }}
                      selectableRowsHighlight
                      progressPending={isLoading}
                      progressComponent={
                        <div style={{ width: "100%" }}>
                          <Skeleton style={{ width: "100%", height: "30px" }} />
                          <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                          <Skeleton style={{ width: "100%", height: "30px" }} />
                          <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>          </div>
       
       </div>
    </div>
 </div>
  )
}

export default PromoCodeTab