import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { useGetUsersMutation } from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton';

function PartnersList() {
  const [getusers, { isLoading }] = useGetUsersMutation();
  const [partners, setPartners] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [perPage, setPerPage] = useState(10); // Items per page
  const [totalRows, setTotalRows] = useState(0); // Total number of partners

  const handleGetUsers = async (page, limit) => {
    try {
      const params = {
        role__icontains: 'partner',
        page: page,
        limit: limit,
      };

      const response = await getusers(params).unwrap();
      console.log(response.results);
      setPartners(response.results);
      setTotalRows(response.count); // Assuming the response includes the total count
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetUsers(page, perPage);
  }, [page, perPage]);

  const filteredItems = partners.filter(
    item => item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      id: 'id',
      name: 'Image 1',
      selector: row => (
        <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
          {row.profile ? (
            <img src={row.profile} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
          ) : ""}
        </div>
      ),
      width: "fit-content", // Set width for Image column
    },
    {
      name: 'Full Name',
      selector: row => (
        <div className='d-flex justify-content-between w-full'>
          <div>
            <h5 className='text-wrap'>{row.first_name + " " + row.last_name}</h5>
            <p>{row.email}</p>
          </div>
          <div className='d-flex justify-content-center align-items-center mx-1'>
            {row.is_number_verified ? <i className="ri-checkbox-circle-fill text-success"></i> : ""}
          </div>
        </div>
      ),
      width: '25%',
    },
    {
      name: 'Promo Code',
      selector: row => (
        <div className='py-1'>
          <p>{row.promo_code}</p>
        </div>
      ),
      width: '10%',
    },
    {
      name: 'Referrals',
      selector: row => (
        <div className='py-1'>
          <p>200</p>
        </div>
      ),
      width: '10%',
    },
    {
      name: 'Discount',
      selector: row => (
        <div className='py-1'>
          <p>200</p>
        </div>
      ),
      width: '10%',
    },
    {
      name: 'Days Left',
      selector: row => (
        <div className='py-1'>
          <p>200</p>
        </div>
      ),
      width: '10%',
    },
    {
      name: 'Actions',
      cell: row => (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Link to={`/partner/${row.id}`} className='text-secondary action-link' size="sm">
            <i className="ri-eye-fill mr-1"></i>
            View
          </Link>
          <Link to={`/partner/edit/${row.id}`} className='text-primary action-link mx-3' size="sm">
            <i className="ri-pencil-fill mr-1"></i>
            Edit
          </Link>
          <div className='text-danger action-link'>
            <i className="ri-delete-bin-fill mr-1"></i>Delete
          </div>
        </div>
      ),
      width: "fit-content",
    },
  ];

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className='d-flex justify-content-between mb-4'>
                <h4 className="card-title">Partners</h4>
                <Link type="button" to="/partner/add" className="btn btn-primary btn-sm mr-2">New Partner</Link>
              </div>

              <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className='text-primary d-flex align-content-center hover-icon'>
                    <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                  </div>
                  <div className="search-box d-flex justify-content-end">
                    <input type="text" className="text search-input" placeholder="Search By Name..."
                      value={filterText}
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ overflowX: 'auto' }}>
                  <div style={{ minWidth: '1100px' }}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangePage={page => setPage(page)} // Update page state
                      onChangeRowsPerPage={newPerPage => {
                        setPerPage(newPerPage); // Update items per page
                        setPage(1); // Reset to first page
                      }}
                      selectableRows
                      selectableRowsComponentProps={{
                        type: 'checkbox',
                      }}
                      selectableRowsHighlight
                      progressPending={isLoading}
                      progressComponent={
                        <div style={{ width: "100%" }}>
                          <Skeleton style={{ width: "100%", height: "30px" }} />
                          <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                          <Skeleton style={{ width: "100%", height: "30px" }} />
                          <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default PartnersList;
