import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetFAQsMutation } from '../../features/property/propertySlice';
import AddNewFAQModal from '../../components/enquiryandfaqs/faqs/addNewFAQModal';
import ViewFAQSModal from '../../components/enquiryandfaqs/faqs/viewFAQsModal';
import EditFAQsModal from '../../components/enquiryandfaqs/faqs/editFAQsModal';
import DeleteFAQModal from '../../components/enquiryandfaqs/faqs/deleteFAQModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomPagination from '../../components/basic/customPagination';

function FAQS() {
    const [getFAQs, { isLoading }] = useGetFAQsMutation();
    const [faqs, setFAQs] = useState([]);
    const [faqDetail, setFaqDetail] = useState(null);
    const [pending, setPending] = useState(true);
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10); // Set your desired rows per page

    const handleGetCategories = async () => {
        try {
            const response = await getFAQs().unwrap();
            setFAQs(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetCategories();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = faqs.filter(
        item => item.question && item.question.toLowerCase().includes(filterText.toLowerCase())
    );

    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const [addfaqModal, setAddFAQModal] = useState(false);
    const toggleModal = () => {
        setAddFAQModal(!addfaqModal);
        handleGetCategories();
    }

    const [viewfaqsModal, setViewFaqModal] = useState(false);
    const [editfaqsModal, setEditFaqsModal] = useState(false);
    const [deletefaqsModal, setDeleteFaqsModal] = useState(false);

    const toggleViewModal = () => {
        setViewFaqModal(!viewfaqsModal);
    }

    const toggleEditModal = () => {
        setEditFaqsModal(!editfaqsModal);
        handleGetCategories();
    }

    const toggleDeleteModal = () => {
        setDeleteFaqsModal(!deletefaqsModal);
        handleGetCategories();
    }

    const handleGetCategoryDetail = (row) => {
        setFaqDetail(row);
        setViewFaqModal(true);
    }

    const handleEditCategory = (row) => {
        setFaqDetail(row);
        setEditFaqsModal(true);
    }

    const handleDeleteCategory = (row) => {
        setFaqDetail(row);
        setDeleteFaqsModal(true);
    }

    const columns = [
        {
            id: 'type',
            name: 'Type',
            selector: row => <div style={{ width: "100px", margin: "0", textTransform: 'capitalize' }}>{row.faq_type}</div>,
            sortable: true,
            reorder: true,
            width: "15%"
        },
        {
            id: 'question_answer',
            name: 'Question & Answer',
            selector: row => (
                <div className='py-1' style={{ width: "300px" }}>
                    <h5>{row.question.length > 30 ? row.question.slice(0, 30) + "..." : row.question}</h5>
                    <p>{row.answer.length > 38 ? row.answer.slice(0, 38) + "..." : row.answer}</p>
                </div>
            ),
            sortable: true,
            reorder: true,
            width: "40%"
        },
        {
            id: 'status',
            name: 'Status',
            selector: row => <h6 style={{ textTransform: 'capitalize' }}> {row.status}</h6>,
            sortable: true,
            reorder: true,
            width: "15%"
        },
        {
            id: 'actions',
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width: "30%"
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Frequently Asked Questions</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New FAQ</button>
                                <AddNewFAQModal addfaqModal={addfaqModal} toggleModal={toggleModal} />
                                <ViewFAQSModal viewfaqsModal={viewfaqsModal} toggleViewModal={toggleViewModal} faqDetail={faqDetail} />
                                <EditFAQsModal editfaqsModal={editfaqsModal} toggleEditModal={toggleEditModal} faqDetail={faqDetail} />
                                <DeleteFAQModal deletefaqsModal={deletefaqsModal} toggleDeleteModal={toggleDeleteModal} faqDetail={faqDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Question..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <DataTable
                                        columns={columns}
                                        data={currentItems} // Use the current items for pagination
                                        progressPending={pending}
                                        pagination
                                        paginationComponent={CustomPagination}
                                        selectableRows
                                        progressComponent={
                                            <div style={{ width: "100%", padding: "10px" }}>
                                                <Skeleton style={{ width: "100%", height: "30px" }} />
                                                <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                <Skeleton style={{ width: "100%", height: "30px" }} />
                                                <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                            </div>
                                        }
                                        selectableRowsHighlight
                                        onSelectedRowsChange={handleChange}
                                        clearSelectedRows={toggledClearRows}
                                        highlightOnHover
                                        onChangePage={handlePageChange} // Handle page change
                                        paginationServer // Use server-side pagination if needed
                                        paginationTotalRows={filteredItems.length} // Total number of rows
                                        paginationPerPage={rowsPerPage} // Rows per page
                                        paginationDefaultPage={currentPage} // Default page
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default FAQS;
