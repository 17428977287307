import React, { useRef, useState, useEffect } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Row, Col } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useAddPromoCodeMutation, useGetUsersMutation } from '../../features/property/propertySlice';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode
);

function PromoCodes() {
    const [files, setFiles] = useState([]);
    const [addPromoCode, { isLoading }] = useAddPromoCodeMutation();
    const [getUsers] = useGetUsersMutation();
    const navigate = useNavigate();
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [partners, setPartners] = useState();
    const formRef = useRef(null);
    const [formData, setFormData] = useState({
        user: '', // Add user ID field
        name: '',
        start_date: '',
        end_date: '',
        percentage: '',
    });

    const handleGetUsers = async () => {
        try {
            const params = { role__icontains: 'partner' };
            const response = await getUsers(params).unwrap();
            console.log(response.results);
            setPartners(response.results);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUserChange = (selectedOption) => {
        if (selectedOption) {
            setFormData(prevState => ({
                ...prevState,
                user: selectedOption.value // Set the user ID in formData
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                user: '' // Clear user ID if no selection
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();

        if (isValid) {
            try {
                console.log(formData);
                
                const response = await addPromoCode(formData);
                console.log(response);

                setSuccess("Success");
                setError();
                setFormData({
                    name: '',
                    promo_code: '',
                    start_date: '',
                    end_date: '',
                    percentage: ''
                });
            } catch (error) {
                console.error(error);
                setError("Try Again with Valid data");
                setSuccess();
            }
        } else {
            formRef.current.classList.add('was-validated');
        }
    };


    

    const [defaultOptions, setDefaultOptions] = useState([]);

    useEffect(() => {
        const fetchInitialUsers = async () => {
            try {
                const params = { role__icontains: 'partner' };
                const response = await getUsers(params).unwrap();
                const data = response.results;

                const options = data.map((item) => ({
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                }));

                setDefaultOptions(options);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialUsers();
    }, []);

    const fetchUsers = async (inputValue) => {
        if (!inputValue) {
            return defaultOptions;
        }

        try {
            const [firstName, lastName] = inputValue.split(' ');
            const params = {
                first_name__icontains: firstName || '',
                last_name__icontains: lastName || '',
                role__icontains: 'partner',
            };
            const response = await getUsers(params).unwrap();
            const data = response.results;

            return data.map((item) => ({
                value: item.id,
                label: `${item.first_name} ${item.last_name}`,
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex flex-column justify-content-between mb-4'>
                                <div className='w-100'>
                                    <div className="">
                                        <h4 className="card-title">Add New Promo Code</h4>
                                    </div>
                                    <form ref={formRef} className='w-100 needs-validation p-2 row' noValidate>
                                        <div className='w-100'>
                                            {success &&
                                                <div className="alert alert-success" role="alert">
                                                    <div className="iq-alert-text">
                                                        <b>Success!</b> Promo Code Added successfully
                                                    </div>
                                                    <button type="button" className="close text-dark" onClick={() => { setSuccess() }} data-dismiss="alert" aria-label="Close">
                                                        <i className="ri-close-line"></i>
                                                    </button>
                                                </div>
                                            }
                                            {error &&
                                                <div className="alert alert-danger" role="alert">
                                                    <div className="iq-alert-text">
                                                        <b>Error!</b> {error}
                                                    </div>
                                                    <button type="button" className="close text-dark" onClick={() => { setError() }} data-dismiss="alert" aria-label="Close">
                                                        <i className="ri-close-line"></i>
                                                    </button>
                                                </div>
                                            }
                                            <div className="row border p-2 rounded-sm">
                                                <div className="col-md-6 mb-2">
                                                    <label>Partner <span className='text-danger'>*</span></label>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        loadOptions={fetchUsers}
                                                        defaultOptions={defaultOptions}
                                                        onChange={handleUserChange} // Use handleUserChange
                                                        placeholder="Search by first and last name..."
                                                        isClearable
                                                        required
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                borderColor: 'gray',
                                                                fontSize: '16px',
                                                                backgroundColor: state.isFocused ? '#E5F2FF' : '#E9EDF4',
                                                            }),
                                                            menu: (base) => ({
                                                                ...base,
                                                                backgroundColor: '#E9EDF4',
                                                            }),
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>Promo Code <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name='name'
                                                        className="form-control"
                                                        required
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>Percentage <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="number"
                                                        name='percentage'
                                                        className="form-control"
                                                        required
                                                        value={formData.percentage}
                                                        onChange={handleChange}
                                                        placeholder='%'
                                                        step="0.1"
                                                        min="0"
                                                        max="100"
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>Begin Date <span className='text-danger'>*</span></label>
                                                    <input
                                                        required
                                                        type="date"
                                                        name='start_date'
                                                        className="form-control"
                                                        value={formData.start_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <label>End Date <span className='text-danger'>*</span></label>
                                                    <input
                                                        required
                                                        type="date"
                                                        name='end_date'
                                                        className="form-control"
                                                        value={formData.end_date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>

                                            

                                            <div className="col-12 d-flex justify-content-start mt-3">
                                                <button type="submit" className="btn btn-primary" onClick={handleSubmit} disabled={isLoading}>
                                                    {isLoading ? "creating..." : "Create Promo Code"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PromoCodes;
